import {
  RfqJobsReducer,
  AllJobsReducer,
  MaintainanceJobsReducer,
  EmployeeJobListReducer,
  AssignJobReducer,
  JobsCounterReducer,
  AssignRequestTypeReducer,
  ExternalJobsReducer
} from "./../screen/dashboard/dashboardPage/reducer";
import { StandardRatesReducer } from "./../screen/dashboard/settings/Rates/standardRates/reducer";
import { DiscountClientListReducer } from "./../screen/dashboard/settings/Rates/discountRates/reducer";

import {
  getStartedStepsCompanyReducer,
  getStartedStepsSoleTraderReducer,
} from "../screen/dashboard/dashboardPage/GetStarted/reducer";

import {
  BusinessProfileReducer,
  profiledetailsdataReducer,
  ImageReducer,
} from "./../screen/dashboard/settings/companyProfile/reducers";
import { MyDetailsReducer } from "./../screen/dashboard/settings/MyDetails/reducer";
import { combineReducers } from "redux";
import {
  logInReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
} from "../screen/login/reducer";
import {
  SignUpReducer,
  profileSelectorReducer,
} from "../screen/signup/reducer";

import {
  employeeTeamPermissionReducer,
  teamMembersDataReducer,
  editadminTeamPermissionReducer,
  adminTeamPermissionReducer,
} from "../screen/dashboard/settings/team/reducer";
import { inviteReducer } from "../screen/dashboard/settings/invite/reducer";
import { selectTemplateModelReducer } from "../screen/sp/selectTemplate/reducer";
import {
  dropDownReducer,
  GlobalImagesLoaderReducer,
  getStartedModalReducer,
} from "./globalReducer";
import { globalLoaderReducer } from "../reducer/globalReducer";
import {
  jobDetailsModelReducer,
  commentsModelReducer,
} from "../screen/sp/spBuildsQuotes/reducer";
import scratchTemplateReducer from "../screen/spScratch/reducer";

import BuildQuoteOwnReducer from "../screen/SpOwn/reducer";
import { scheduleBookingReducer } from "../screen/dashboard/dashboardPage/Bookings/reducer";
import { CompanyClientListReducer } from "./../screen/LandingPage/reducer";
import { savedCompanyListingReducer } from "./../screen/LandingPage/reducer";
import {
  FinancialOnboardingReducer,
  FinancialOnboardingDownloadReducer,
} from "../../src/screen/dashboard/settings/financial/reducer";
import { notificationListingReducer } from "../components/Navbar/reducer";
import { serviceProviderSearchReducer } from "../screen/ProjectSupport/reducer";
import { jobDetailsReducer } from "../screen/ViewDetails/reducer";
import { externalTradeDetailsReducer } from "../screen/MaintenanceSupport/reducer";
import { companyDetailsReducer } from "./../screen/LandingPage/reducer";
import { globalReducer } from "./globalReducer";

const rootReducer = combineReducers({
  ExternalJobsReducer,
  FinancialOnboardingDownloadReducer,
  FinancialOnboardingReducer,
  profiledetailsdataReducer,
  globalLoaderReducer,
  globalReducer,
  logInReducer,
  forgotPasswordReducer,
  resetPasswordReducer,
  SignUpReducer,
  profileSelectorReducer,
  dropDownReducer,
  employeeTeamPermissionReducer,
  inviteReducer,
  teamMembersDataReducer,
  MyDetailsReducer,
  BusinessProfileReducer,
  StandardRatesReducer,
  ImageReducer,
  editadminTeamPermissionReducer,
  adminTeamPermissionReducer,
  DiscountClientListReducer,
  selectTemplateModelReducer,
  GlobalImagesLoaderReducer,
  RfqJobsReducer,
  MaintainanceJobsReducer,
  AllJobsReducer,
  EmployeeJobListReducer,
  jobDetailsModelReducer,
  commentsModelReducer,
  getStartedModalReducer,
  getStartedStepsCompanyReducer,
  getStartedStepsSoleTraderReducer,
  scratchTemplateReducer,
  BuildQuoteOwnReducer,
  AssignJobReducer,
  JobsCounterReducer,
  scheduleBookingReducer,
  CompanyClientListReducer,
  savedCompanyListingReducer,
  AssignRequestTypeReducer,
  notificationListingReducer,
  serviceProviderSearchReducer,
  jobDetailsReducer,
  externalTradeDetailsReducer,
  companyDetailsReducer
});

export default rootReducer;
