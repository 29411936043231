import { GlobalLoaderModal, GlobalModal } from "../model";
import { DropDownListsModel, EmployeeDropdownListModel } from "../model";
import Utils from "../Utils";
import ActionName from "../Utils/actionName";

export const globalLoaderReducer = (
  state: GlobalLoaderModal = new GlobalLoaderModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.LOADING:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const globalReducer = (
  state: GlobalModal = new GlobalModal(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.TUTORIAL_STEP:
      return { ...state, tutorialStep: action.payload };
    default:
      return state;
  }
};

export const GlobalImagesLoaderReducer = (
  state = {
    current: 0,
    total: 0,
    loading: false,
  },
  action: any
) => {
  switch (action.type) {
    case "IMAGE_UPLOAD_LOADER_START":
      return {
        ...state,
        ...action.payload,
      };
    case "IMAGE_UPLOAD_LOADER_INCREMENT":
      return {
        ...state,
        ...action.payload,
      };
    case "IMAGE_UPLOAD_LOADER_END":
      return {
        loading: false,
        total: 0,
        current: 0,
      };
    default:
      return state;
  }
};

export const dropDownReducer = (
  state: DropDownListsModel = new DropDownListsModel(),
  action: any
) => {
  switch (action.type) {
    case Utils.ActionName.DROPDOWN:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return { ...state };
  }
};

// export const emloyeeJobReducer = (
//   state: EmployeeDropdownListModel = new EmployeeDropdownListModel(), 
//   action: any
// ) => {
//   switch (action.type) {
//     case Utils.ActionName.GET_EMPLOYEE_LIST_DASHBOARD:
//       return {
//         ...state,
//         ...action.payload,
//       };

//     default:
//       return { ...state };
//   }
// };

export const getStartedModalReducer = (
  state: any = {
    open: false,
    toolTip: localStorage.getItem("toolTip") ? true : false,
  },
  action: any
) => {
  switch (action.type) {
    case ActionName.GETSTARTED_MODAL_OPEN:
      return {
        ...state,
        open: true,
      };
    case ActionName.GETSTARTED_MODAL_CLOSE:
      return {
        ...state,
        open: false,
      };
    case ActionName.GETSTARTED_TOOLTIP_OPEN:
      return {
        ...state,
        toolTip: true,
      };
    case ActionName.GETSTARTED_TOOLTIP_CLOSE:
      return {
        ...state,
        toolTip: false,
      };

    default:
      return state;
  }
};

// actions...

//image loader actions
export const ImageUploadLoaderStart = (obj: {
  current: number;
  total: number;
  loading: boolean;
}) => {
  return {
    type: "IMAGE_UPLOAD_LOADER_START",
    payload: obj,
  };
};

export const ImageUploadLoaderIncrement = (obj: { current: number }) => {
  return {
    type: "IMAGE_UPLOAD_LOADER_INCREMENT",
    payload: obj,
  };
};

export const ImageUploadLoaderEnd = () => {
  return {
    type: "IMAGE_UPLOAD_LOADER_END",
  };
};

export const getImageLoaderState = (state: any) =>
  state?.GlobalImagesLoaderReducer;

// global loader actions
export const globalLoaderStart = () => {
  return {
    type: Utils.ActionName.LOADING,
    payload: { isLoading: true },
  };
};

export const globalLoaderEnd = () => {
  return {
    type: Utils.ActionName.LOADING,
    payload: { isLoading: false },
  };
};

// get Started actions

export const getStartedModalOpen = () => {
  return {
    type: ActionName.GETSTARTED_MODAL_OPEN,
  };
};

export const getStartedModalClose = () => {
  return {
    type: ActionName.GETSTARTED_MODAL_CLOSE,
  };
};

export const getStartedToolTipOpen = () => {
  return {
    type: ActionName.GETSTARTED_TOOLTIP_OPEN,
  };
};

export const getStartedToolTipClose = () => {
  return {
    type: ActionName.GETSTARTED_TOOLTIP_CLOSE,
  };
};

export const hideGetStartedTooltipPermanent = () => (dispatch: any) => {
  Utils.API.postApiCall(
    Utils.endpoint.tooltip,
    { toolTip: true },
    () => {
      dispatch(getStartedToolTipClose());
      localStorage.removeItem("toolTip");
    },
    (error: any) => {
      console.log("error tooltip", error);
      Utils.showAlert(2, "Something went wrong");
      // console.log("tooltip error", error);
    }
  );
};

export const GetStartedTooltipRemindMeLater = () => (dispatch: any) => {
  Utils.API.postApiCall(
    Utils.endpoint.tooltip,
    { toolTip: false },
    () => {
      dispatch(getStartedToolTipClose());
      localStorage.removeItem("toolTip");
    },
    (error: any) => {
      console.log("error tooltip", error);
      Utils.showAlert(1, "Something went wrong");
      // console.log("tooltip error", error);
    }
  );
};

export const getStartedModalState = (state: any) =>
  state.getStartedModalReducer;
