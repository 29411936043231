import React from "react";
import { Button, ButtonProps, useTheme } from "@mui/material";

const NormalButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, disabled, ...rest }, ref) => {
    const attributes: ButtonProps = {
      ...rest,
      fullWidth: true,
      disabled: disabled,
    };
    const theme = useTheme();
    return <Button ref={ref} {...attributes}>{children}</Button>;
  }
);

export default NormalButton;
