const LocalImages = {
  Add: "/images/add.png",
  Construction: "/images/banner1.png",
  Toolkit: "/images/banner2.png",
  Boot: "images/banner3.png",
  Hand: "/images/banner3.png",
  Plan: "/images/banner4.png",
  Build: "/images/build.png",
  Logo: "/images/logo.png",
  Client: "/images/ic-client-unselected.png",
  ClientSelected: "/images/ic-client-selected.png",
  Work: "/images/ic-work-unselected.png",
  WorkSelected: "/images/ic-work-selected.png",
  arrowLeft: "/images/arrowLeft.png",
  arrowRight: "/images/arrowRight.png",
  Users: "/images/users.png",
  Notification: "/images/notification.png",
  Suitcase: "/images/suitcase.png",
  Person: "/images/person.png",
  Dollar: "/images/dollar.png",
  BusinessProfile: "/images/company_profile.png",
  upload: "/images/upload.png",
  rectangle: "/images/Rectangle.png",
  circle: "/images/circle.png",
  edit: "/images/edit.png",
  deleteimg: "/images/delete.png",
  delete: "/images/delete.svg",
  arrowBack: "/images/arrowBack.png",
  coolicon: "/images/coolicon.svg",
  clockIcon: "/images/clockIcon.svg",
  a2Milk: "/images/a2Milk.svg",
  facebook: "/images/facebook.png",
  insta: "/images/insta.png",
  placeholderDiscount: "/images/placeholderDiscount.png",
  csvLogo: "/images/csvLogo.png",
  ExcelIcon: "/images/excelIcon.png",
  PdfIcon: "/images/filepdf.png",
  DocIcon: "/images/docIcon.jpeg",
  PngIcon: "/images/PngFile.png",
  JpegIcon: "/images/JpegFile.png",
  ScratchIcon: "/images/Scratch.png",
  TemplateIcon: "/images/Template.png",
  UploadIcon: "/images/Upload.png",
  UploadDoc: "/images/UploadDoc.png",
  verticalDots: "/images/verticalDots.png",
  horizontalDots: "/images/horizontalDots.png",
  crossIcon: "/images/crossIcon.png",
  attachmentIcon: "/images/attachmentIcon.svg",
  bookmarkIcon: "/images/bookmark.svg",
  filterViewAll: "/images/filterViewall.png",
  messagephoto: "/images/message.png",
  dotIcon: "/images/dotIcon.svg",
  AvatarIcon: "/images/AvatarIcon.png",
  DragIcon: "/images/drag.png",
  SpOwnUpload: "/images/SpOwnUpload.png",
  BqOwnFolder: "/images/BqOwnFolder.png",
  downloadBqOwn: "/images/downloadbqown.png",
  folderAlternate: "/images/folder-alternate.png",
  arrowDown: "/images/arrowDown.png",
  addIcon: "/images/AddIconBlue.png",
  checkIcon: "/images/checkIcon.png",
  certificatePlaceholder: "/images/certificatePlaceholder.png",
  unverified: "/images/unverified.png",
  noComments: "/images/noComments.png",
  noEmployee: "/images/noEmployee.png",
  booking: "/images/booking.jpg",
  multiUSer: "/images/multiUser.png",
  search: "/images/search.png",
  filter: "/images/Filter.png",
  location: "/images/location.png",
  HomePageBackground: "../images/shutterstock.png",
  Horizontal_Dot: "../images/horizontal_dot.png",
  Vector: "../images/Vector.png",
  ToolBox: "../images/tool-box.png",
  BlueSearch: "../images/blueSearch.png",
  Ham: "/images/hamburger.png",
  Calender: "/images/calender.png",
  ellipse: "/images/Ellipse.png",
  blueup: "/images/blueUp.png",
  financial: "/images/dollor.png",
  down: "/images/down.png",
  mail: "/images/invite.png",
  BlueDot: "/images/blue_dot.png",
  MarkRead: "/images/readMark.png",
  Remove: "/images/remove.png",
  UserProfile: "/images/userprofile.png",
  Notifications_dot: "/images/redbellIcon.png",
  PdfIconWhite: "../icon/pdficonwhite.png",
  magnifier: "../icon/magnifier.png",
};
export default LocalImages;
