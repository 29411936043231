import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  bookingall: () => ({
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    padding: "10px 0px 10px 2px",
    maxHeight: "700px",
    overflowY:"scroll",
    minHeight: "700px",
  }),
  looksfor: () => ({
    padding: "6px 0px 10px 15px",
    color: "#333333",
    fontSie: "16px",
    fontWeight: "500",
  }),
  scheduleTitle: ({ theme }: any) => ({
    "&.MuiTypography-root": {
      fontSize: "18px",
      fontWeight: "500",
      color: "#4F4F4F",
      padding: "8px 0px 10px 12px",
      borderBottom: "1px solid #E0E0E0",
      [theme.breakpoints.down(600)]: {
        paddingLeft: "20px",
      },
    },
  }),
  phoneBox: ({ theme }: any) => ({
    display: "flex",
    color: "#4F4F4F",
    fontSize: "16px",
    fontWeight: "400",
    margin: "8% 7% 7% 9%",
    [theme.breakpoints.down(600)]: {
      margin: "8% 3% 7% 3%",
    },
  }),
  aboutBox: () => ({
    marginTop: "3%",
    marginBottom: "5%",
  }),
  businessTagline: () => ({
    "&.MuiTypography-root": {
      color: "#424546",
      fontSize: "14px",
      fontWeight: "400",
    },
  }),
  Headline: ({ theme }: any) => ({
    "&.MuiTypography-root": {
      color: "#4F4F4F",
      fontSize: "14px",
      fontWeight: "500",
      margin: "2% 0% 2% 0%",
      [theme.breakpoints.down(600)]: {
        margin: "3% 0% 3% 0%",
      },
    },
  }),
  trades: ({ theme }: any) => ({
    padding: "5px 10px 3px 10px",
    margin: "1% 2% 2% 0%",
    background: "#F2F4F7",
    color: "#344054",
    minHheight: "22px",
    maxHeight: "100px",
    fontSize: "14px",
    fontWeight: "500",
    textAlign: "center",
    maxWidth: "300px",
    textTransform: "capitalize",
    [theme.breakpoints.down(600)]: {
      margin: "2% 2% 2% 0%",
    },
  }),
   bookNow: () => ({
    background: "#FFFFFF",
    border: "1px solid #E0E0E0",
    paddingTop: "3%",
    minHeight: "700px",
  }),
  requestTitle: () => ({
    "&.MuiTypography-root": {
      fontSize: "18px",
      fontWeight: "700",
      color: "#333333",
      lineHeight: "1.7",
      textTransform: "capitalize",
    },
  }),
  requestTitle1: () => ({
    "&.MuiTypography-root": {
      fontSize: "18px",
      fontWeight: "700",
      color: "#333333",
      lineHeight: "1.7",
      textTransform: "capitalize", 
    },
  }),
  location: () => ({
    "&.MuiTypography-root": {
      fontSize: "14px",
      fontWeight: "400",
      color: "#4F4F4F",
      lineHeight: "1.5",
    },
  }),
  dontHaveAccount: () => ({
    "& a": {
      textDecoration: "none",
      color: "#097390",
      fontSize: "16px",
      marginLeft: "-70px",
    },
  }),
  searchInput: () => ({
    "& .MuiInputBase-root": {
      height: "44px",
      font: "normal normal normal 18px/20px Inter",
      letterSpacing: "0.05px",
      // color: "#515151",
      opacity: 1,
      borderRadius: 0,
      margin: "0% 1% 10% 5%",
      backgroundColor: "rgba(224, 224, 224, 0.4)",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        boxShadow: "-2px 0px 23px -2px rgba(0,0,0,0.33)",
        border: "1px solid #000000",
        fontFamily: "Inter",
        backgroundColor: "rgba(224, 224, 224, 0.4)",
        // background: "#4f4f4f",
      },
    },
  }),
  checboxContainer: () => ({
    borderRadius: "0px",
  }),
  filterstructure: () => ({
    padding: "5px 0px 5px 15px",
  }),
  filter: () => ({
    display: "flex",
    flexDirection: "column",
    padding: "5px 0px 5px 15px",
  }),
  mainContainer: () => ({
    position: "relative",
    height: "100%",
    width: "100%",
    maxWidth: "1280px",
    margin: "0 auto",
    backgroundColor: "#ffffff",
    // marginTop: "20%",
  }),
  styleButton: () => ({
    "&.MuiButton-root": {
      mr: 2,
      borderRadius: 0,
      textTransform: "capitalize",
      width: "124px",
    },
  }),
  links: () => ({
    "&.MuiTypography-root": {
      color: "#333333",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "35px",
    },
  }),
  savedCompany: () => ({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "2%",
    // padding: "15px 10px 15px 20px",
  }),
  box: () => ({
    borderBottom: "1px solid #E0E0E0",
    padding: "25px 20px 25px 20px",
  }),
  viewMore: () => ({
    fontSize: "14px",
    fontWeight: "400",
    color: "#424546",
    display: "Inline",
    width: "100%",
  }),
  container: () => ({
    position: "absolute",
    top: "10%",
    left: "23%",
    width: "50%",
  }),
  subTitle: () => ({
    "&.MuiTypography-root": {
      fontWeight: 500,
      fontSize: "16px",
      color: "#424546",
      marginTop: "3%",
      marginBottom: "3%",
    },
  }),
  inputField: ({ theme }: any) => ({
    marginBottom: "5%",
    color: "black",
    background: "#fff",
    width: "267px",
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },
  }),

});
export { useStyles };
