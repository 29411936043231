import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import NewCostButton from "../../../components/button/newCostButton";
import CostInputField from "../../../components/inputfield/CostInputField";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Typography, TextField } from "@mui/material";
import {
  TitleField,
  CostContainer,
  CostHeadingRow,
  CostBodyRow,
  CostCol,
  SVGDragContainer,
} from "../styles";
import {
  COST_ITEM_SCHEMA,
  TABLE_HEADINGS,
  getInitialValue,
  checkErrors,
} from "./helper";
import Schema from "../../../schema";
import LocalImages from "../../../Utils/localImages";
import Utils from "../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { ReducersModel } from "../../../model";
import { getAmountAud } from "../helper";
import { flexbox } from "@mui/system";

const { DragIcon } = LocalImages;

const {
  ActionName: { SCRATCH_TEMPLATE },
} = Utils;

const CostsForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  provided,
  submitRef,
  index,
}: any) => {
  const dispatch = useDispatch();
  const { errorsForms } = useSelector(
    (state: ReducersModel) => state.scratchTemplateReducer
  );
  const { draggableForms } = useSelector(
    (state: ReducersModel) => state.scratchTemplateReducer
  );
  const form = draggableForms.filter((forms: any) => forms.boxType === 1)[0];

  const isReadyForSubmit = checkErrors(errors, touched);

  const stringifyObj = JSON.stringify(values);

  const computeSubTotal = () => {
    // console.log("ZZZ", form?.costItems);
    let number: number = 0;
    form?.field?.costItems?.forEach((data: any) => {
      const amount = Number(data.quantity) * Number(data.unitPrice);
      const discount = (amount * Number(data.discount)) / 100;
      console.log("ZZZ", number, amount, discount, data);
      number = number + (amount - discount);
    });
    console.log("ZZZ", number);
    return number;
  };
  React.useEffect(() => {
    if (isReadyForSubmit && submitRef && submitRef.current) {
      submitRef.current.click();
    }

    console.log("Costs/ZZZ");
  }, [isReadyForSubmit, index, stringifyObj]);

  console.log("XXX", { values, errors, touched });

  return (
    <Form>
      <SVGDragContainer
        onClick={(e: any) => e.stopPropagation()}
        {...provided.dragHandleProps}
      >
        <img src={DragIcon} alt="drag" />
      </SVGDragContainer>
      <TitleField name="title" value={values.title} readOnly />
      <CostHeadingRow>
        {TABLE_HEADINGS.map((heading: { [key: string]: any }) => (
          <CostCol
            className="costTable"
            width={heading.width}
            {...(heading.isBdr ? { $bdr: true } : {})}
            key={heading.name}
            justifyContent={"flex-start"}
          >
            {heading.headText}
          </CostCol>
        ))}
      </CostHeadingRow>
      {values.costItems.map((row: any, rowIndex: number) => (
        <CostBodyRow key={`row-${rowIndex}`}>
          {TABLE_HEADINGS.map((col: any, colIndex: number) => {
            return (
              <CostCol
                width={col.width}
                {...(col.isBdr ? { $bdr: true } : {})}
                key={`row-${rowIndex}_col-${colIndex}`}
                justifyContent={"center"}
                className="costTable"
              >
                {col.isField && (
                  <CostInputField
                    name={`costItems.${rowIndex}.${col.name}`}
                    value={values.costItems[rowIndex][col.name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={Boolean(
                      touched &&
                      Array.isArray(touched.costItems) &&
                      touched.costItems.length > 0 &&
                      touched.costItems[rowIndex] &&
                      (touched.costItems[rowIndex] as any)[col.name]
                    )}
                    error={
                      errors &&
                      Array.isArray(errors.costItems) &&
                      errors.costItems.length > 0 &&
                      errors.costItems[rowIndex] &&
                      (errors.costItems[rowIndex] as any)[col.name]
                    }
                  />
                )}
                {col.headText === "Amount AUD" &&
                  getAmountAud(values.costItems[rowIndex])}
                {col.headText === "" && rowIndex === 0 && (
                  <React.Fragment></React.Fragment>
                )}
                {col.headText === "" && rowIndex > 0 && (
                  <ClearIcon
                    onClick={() => {
                      let localCostItems = [...values.costItems];
                      localCostItems.splice(rowIndex, 1);
                      console.log(localCostItems, "lcIt");
                      setFieldValue("costItems", [...localCostItems]);
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </CostCol>
            );
          })}
        </CostBodyRow>
      ))}
      <NewCostButton
        // $height={40}
        $width={200}
        onClick={(event: any) => {
          setFieldValue("costItems", [...values.costItems, COST_ITEM_SCHEMA]);
          const localErrorsForms = [...errorsForms];
          localErrorsForms[index] = false;
          dispatch({
            type: SCRATCH_TEMPLATE,
            payload: {
              errorsForms: localErrorsForms,
            },
          });
        }}
      >
        {"Add a new line"}
      </NewCostButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          justifyContent: "space-between",
          // position: "relative",
          // left: "70%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "10%",
            // width: "200px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "262px",
            }}
          >
            <Typography
              sx={{
                color: "#333333",
                fontSize: "14px",
                width: "50%",
                textAlign: "right",
              }}
            >
              Subtotal
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: "14px",
                width: "50%",
                textAlign: "right",
              }}
            >
              {computeSubTotal().toFixed(2)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#333333",
                fontSize: "14px",
                width: "50%",
                textAlign: "right",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              Total Gst 10%
            </Typography>
            <Typography
              sx={{
                color: "#333333",
                fontSize: "14px",
                width: "50%",
                textAlign: "right",
                paddingTop: "5px",
                paddingBottom: "5px",
              }}
            >
              {((computeSubTotal() * 10) / 100).toFixed(2)}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{ height: "1px", backgroundColor: "#000", width: "262px" }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontSize: "18px",
                textAlign: "right",
              }}
            >
              <Typography
                sx={{
                  width: "50%",
                  fontSize: "18px !important",
                  fontWeight: "bold",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  textAlign: "right",
                }}
              >
                Total
              </Typography>
              <Typography
                sx={{
                  width: "50%",
                  fontSize: "18px !important",
                  fontWeight: "bold",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
              >
                {(computeSubTotal() + (computeSubTotal() * 10) / 100).toFixed(
                  2
                )}
              </Typography>
            </Box>
            <Box
              sx={{ height: "1px", backgroundColor: "#000", width: "262px" }}
            />
            <Box
              sx={{
                height: "1px",
                backgroundColor: "#000",
                width: "262px",
                marginTop: "1%",
              }}
            />
          </Box>
        </Box>
      </Box>
      <button type="submit" style={{ display: "none" }} ref={submitRef} />
    </Form>
  );
};

export default function CostsTable({ provided, field, index }: any) {
  const submitRef = React.useRef(null);
  const dispatch = useDispatch();
  const [isActive, setIsActive] = React.useState(false);
  const { draggableForms, errorsForms } = useSelector(
    (state: ReducersModel) => state.scratchTemplateReducer
  );

  return (
    <CostContainer
      ref={provided.innerRef}
      {...provided.draggableProps}
      onClick={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      isActive={isActive}
      cursor="pointer"
    >
      <Formik
        initialValues={getInitialValue(true, field)}
        validationSchema={Schema.ScratchDraggableSchema2}
        onSubmit={(values: any) => {
          console.log("Costs/FinalValues", values);
          const localDraggableForms = [...draggableForms];
          localDraggableForms[index].field = {
            ...values,
          };
          const localErrorsForms = [...errorsForms];
          localErrorsForms[index] = true;
          dispatch({
            type: SCRATCH_TEMPLATE,
            payload: {
              draggableForms: localDraggableForms,
              errorsForms: localErrorsForms,
            },
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => {
          const costsAttributes: any = {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            setFieldValue,
            provided,
            submitRef,
            index,
          };

          return <CostsForm {...costsAttributes} />;
        }}
      </Formik>
    </CostContainer>
  );
}
