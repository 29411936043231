/* eslint-disable no-lone-blocks */
import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  useTheme,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { styled } from "@mui/system";
import LocalImages from "../../Utils/localImages";
import Modal from "@mui/material/Modal";
import { useStyles } from "./style";
import Utils from "../../Utils";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReducersModel } from "../../model";
import { getNotificationListData, updateNotification } from "./action";
import CommonFunction from "../../Utils/commonFunctions";

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  margin: "2rem auto",
  alignItems: "center",
  textAlign: "center",
});

const StyledTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: "500",
  color: "#333333",
  margin: "1% 0% 0% 0%",
});
const StyledMessageTypography = styled(Typography)({
  fontSize: "16px",
  fontWeight: "500",
  color: "#000000",
  margin: "0 0 0 0",
});

const StyledSubTypography = styled(Typography)({
  fontSize: "14px",
  fontWeight: "400",
  color: "#828282",
  margin: "0% 0% 2% 0%",
});

const AvatarCircle = styled(Avatar)({
  backgroundColor: "#F2F2F2",
  color: "#F2F2F2",
  width: "46px",
  height: "46px",
});

type PropTypes = {
  ModalOpen: boolean;
  handleLogout?: any;
  setModalOpen?: any;
};

const NotificationModal = ({ ModalOpen, setModalOpen }: PropTypes) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down(700));

  useEffect(() => {
    dispatch(getNotificationListData());
    setInterval(() => dispatch(getNotificationListData()), 15000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { notificationListData } = useSelector(
    (state: ReducersModel) => state.notificationListingReducer
  );

  const allId =
    notificationListData &&
    notificationListData.map((el: any) => {
      return el?._id;
    });

  const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: "15rem",
      fontSize: "1.3rem",
      border: "1px solid #dadde9",
      marginLeft: "1020px",
    },
    [`& .${tooltipClasses.arrow}`]: {
      "&::before": {
        background: "#fff",
        border: "1px solid #dadde9",
        display: "none",
        // marginLeft: "15px",
      },
      // transform: isMobile
      //   ? "translate(170px, 0px) !important"
      //   : "translate(100px, 0px) !important",
    },
  }));

  // console.log("notificationListData", notificationListData);

  const NavToolTip = (props: any) => {
    return (
      <>
        <CustomToolTip
          placement="bottom-end"
          enterTouchDelay={0}
          title={
            <Stack direction="column" sx={{ width: "12rem" }}>
              {/* <Typography color="inherit" sx={{ paddingY: ".7rem" }}> */}
              {/* <Link
                  to={{
                    pathname: "",
                  }}
                  // onClick={() =>
                  //   handleClick(
                  //     props.data.status,
                  //     props.data._id,
                  //     props.data.requestType
                  //   )
                  // }
                  onClick={(e: any) => {
                    dispatch(updateNotification(1, e?.id));
                  }}
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    src={LocalImages.MarkRead}
                    alt=""
                    height="10px"
                    width="13px"
                  />
                  <span
                    style={{
                      color: "#4F4F4F",
                      fontSize: "14px",
                      marginLeft: "10px",
                    }}
                  >
                    Mark as unread
                  </span>
                </Link> */}
              {/* </Typography> */}
              <Typography
                onClick={() => {
                  console.log("dhfgdjhgdf", props?.data?._id);
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  dispatch(updateNotification(4, Array(props?.data?._id)));
                }}
                color="inherit"
                sx={{
                  cursor: "pointer",
                  paddingY: ".7rem",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <img
                  src={LocalImages.MarkRead}
                  alt=""
                  height="15px"
                  width="13px"
                />
                <span
                  style={{
                    color: "#4F4F4F",
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Mark as unread
                </span>
              </Typography>
              <Typography
                onClick={() => {
                  dispatch(updateNotification(2, Array(props?.data?._id)));
                }}
                color="inherit"
                sx={{
                  cursor: "pointer",
                  paddingY: ".7rem",
                  color: "inherit",
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <img
                  src={LocalImages.Remove}
                  alt=""
                  height="15px"
                  width="13px"
                />
                <span
                  style={{
                    color: "#4F4F4F",
                    fontSize: "14px",
                    marginLeft: "10px",
                  }}
                >
                  Remove Notification
                </span>
              </Typography>
            </Stack>
          }
          arrow
        >
          {props.children}
        </CustomToolTip>
      </>
    );
  };
  const handleOnclick = (el: any) => {
    if (el?.isRead === false) {
      dispatch(updateNotification(1, Array(el?._id)));
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  return (
    <Modal
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      open={ModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ background: "transparent" }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={(theme) => ({
          width: "450px",
          minHeight: "100px",
          backgroundColor: "#fff",
          outline: "none",
          position: "fixed",
          top: "9%",
          left: notificationListData.length > 0 ? "60%" : "52%",
          // height: "300px",
          // overflow: "auto",
          padding: "1.6rem 1.5rem",
          "&.MuiBackdrop-root-MuiModal-backdrop": {
            background: "transparent",
          },
          [theme.breakpoints.down("md")]: {
            width: "90%",
            left: "5%",
          },
        })}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography>Notifications</Typography>
          {notificationListData?.length > 0 ? (
            <Button
              sx={{ textTransform: "none" }}
              onClick={() => {
                dispatch(updateNotification(1, allId));
              }}
            >
              Mark as read
            </Button>
          ) : (
            <Button sx={{ textTransform: "none" }}>Mark as read</Button>
          )}
        </Box>
        {notificationListData && notificationListData.length > 0 ? (
          <Box sx={{ overflow: "auto", height: "400px" }}>
            {notificationListData.length > 0 &&
              notificationListData.map((el: any) => {
                return (
                  <Box
                    sx={{ display: "flex", cursor: "pointer" }}
                    key={el}
                    onClick={() => {
                      setModalOpen(false);
                      {
                        if (
                          el?.type === "CONFIRM_JOB_COMPANY" ||
                          el?.type === "COMMENTS" ||
                          el?.type === "QUOTE_CONFIRM" ||
                          el?.type === "REJECT_QUOTE"
                        ) {
                          history.push(
                            `${Utils.Pathname.JOB_DETAILS}/${el?.subjectId}`
                          );
                          if (el?.isRead === false) {
                            dispatch(updateNotification(1, Array(el?._id)));
                          }
                        } else if (el?.type === "COMPLETE_JOB") {
                          history.push(Utils.Pathname.ViewAllRequest);
                          if (el?.isRead === false) {
                            dispatch(updateNotification(1, Array(el?._id)));
                          }
                        } else if (el?.type === "CREATE_JOB" || el?.type === "EXPIRED_JOB") {
                          history.push(
                            `${Utils.Pathname.JOB_DETAILS}/${el?.subjectId}`
                          );
                          if (el?.isRead === false) {
                            dispatch(updateNotification(1, Array(el?._id)));
                          }
                        } else if (
                          (el?.type === "ROLE_PERMISSION_UPDATED" &&
                            localStorage.getItem("roles") === "SUPER_ADMIN") ||
                          el?.type === "INVITED_EMPLOYEE_SIGNUP" ||
                          el?.type === "UPLOAD_CERTIFICATE" ||
                          el?.type === "EMPLOYEE_DELETED_BY_ADMIN" ||
                          el?.type === "EMPLOYEE_PROFILE_COMPLETED" ||
                          (el?.type === "ADD_TEAM" &&
                            localStorage.getItem("roles") === "SUPER_ADMIN")
                        ) {
                          history.push(Utils.Pathname.Settings.Teams);
                          if (el?.isRead === false) {
                            dispatch(updateNotification(1, Array(el?._id)));
                          }
                        } else if (el?.type === "FINANCIAL_ONBOARDING") {
                          history.push(Utils.Pathname.Settings.Financial);
                          if (el?.isRead === false) {
                            dispatch(updateNotification(1, Array(el?._id)));
                          }
                        } else {
                          if (el?.isRead === false) {
                            dispatch(updateNotification(1, Array(el?._id)));
                          }
                          history.push(Utils.Pathname.Dashboard);
                        }
                      }
                    }}
                  >
                    <Box>
                      <Avatar
                        src={
                          el?.businessProfilePicture
                            ? el?.businessProfilePicture
                            : el?.profilePicture
                              ? el?.profilePicture
                              : LocalImages.circle
                        }
                        sx={{
                          margin: "25px .75rem 0px 0px",
                          height: "45px",
                          width: "45px",
                        }}
                        variant="circular"
                        alt="avatar"
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <StyledMessageTypography>
                        {el?.title}
                        {el?.isRead === false ? (
                          <Button
                            sx={{ float: "right", height: "15px" }}
                            onClick={() => {
                              dispatch(updateNotification(1, Array(el?._id)));
                            }}
                          >
                            <img
                              src={LocalImages.BlueDot}
                              alt=""
                              height="20px"
                              width="8px"
                              style={{ float: "right", paddingTop: "12px" }}
                            />
                          </Button>
                        ) : (
                          <NavToolTip dispatch={dispatch} data={el}>
                            <Button
                              onClick={(e: any) => e.stopPropagation()}
                              sx={(theme) => ({
                                float: "right",
                                fontSize: "16px",
                                [theme.breakpoints.down("md")]: {},
                                borderRadius: 0,
                                color: "#4f4f4f",
                                "&:hover": {
                                  background: "transparent",
                                  color: "#4f4f4f",
                                },
                              })}
                              disableRipple
                            >
                              <img src={LocalImages.Horizontal_Dot} alt="" />
                            </Button>
                          </NavToolTip>
                        )}
                      </StyledMessageTypography>
                      <StyledTypography>
                        {el?.message.replaceAll("_", " ")}
                      </StyledTypography>
                      <StyledSubTypography>
                        {`${CommonFunction.getNotificationTimeStatus(
                          el.createdAt
                        )} ago`}
                      </StyledSubTypography>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        ) : (
          <Box className={classes.modalBox}>
            {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Notifications</Typography>
              <Button sx={{ textTransform: "none" }}>Mark as read</Button>
            </Box> */}

            <StyledBox>
              <AvatarCircle>
                <img src={LocalImages.Notifications_dot} alt="arrow" />
              </AvatarCircle>
              <StyledTypography>You’re All Caught Up</StyledTypography>
              <StyledSubTypography>
                Notifications about your activity will <br />
                show up here.
              </StyledSubTypography>
            </StyledBox>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default NotificationModal;
