import { Box } from '@mui/material';
import { useStyles } from '../../sp/spBuildsQuotes/spBuildsQuotesCss';

const Title = ({ children }: any) => {
  const classes = useStyles();
  return (
    <div>
      <Box className={classes.titleWrapper}>
        <Box className={classes.title}>{children}</Box>
      </Box>
    </div>
  );
};

export default Title;
