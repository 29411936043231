import React from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { FieldContainer } from "../../../components/styledComponents";
import InputField from "../../../components/inputfield/ScratchQuoteInput";
import { useStyles } from "../../sp/selectTemplate/selectTemplateCss";
import CustomDatePicker from "../../../components/DataTimePicker/ScratchQuoteDatePicker";
import moment from "moment";
import { getInitialValues, checkErrors } from "./helper";
import { Formik, Form } from "formik";
import Schema from "../../../schema";
import useRedux from "../../../hooks/useRedux";
import Utils from "../../../Utils";
import PaperBody from "../../sp/PaperBody";
import TextfieldWrapper from "../../../components/Textfield";
import DateTimePicker from "../../../components/DataTimePicker";

const {
  ActionName: { SCRATCH_TEMPLATE },
} = Utils;

const QuoteForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  submitRef,
  isValid,
  dirty,
}: any) => {
  const { dispatch, reducers } = useRedux();
  const classes = useStyles();

  const stringifyObj = JSON.stringify(values);
  const { draggableForms, errorsForms, clientErrors, clientDetails } =
    reducers.scratchTemplateReducer;

  const { JobDetailsData } = reducers.jobDetailsModelReducer;

  React.useEffect(() => {
    if (isValid && dirty && submitRef && submitRef.current) {
      submitRef.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, dirty, stringifyObj]);

  console.log(
    { values, clientDetails, errors, touched, isValid, dirty },
    "Pname"
  );

  return (
    <Form>
      <PaperBody isHeightAuto>
        <Box className={classes.gridMuiroot}>
          <Grid container>
            <Grid item xs={12} sm={12} md={3.5}>
              <Typography sx={{ padding: "3px 0px 10px" }} variant="h5">
                Client Name
              </Typography>

              <TextfieldWrapper
                name="clientname"
                className={classes.textField}
                size="small"
                value={values.clientname}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2.5}></Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h5" sx={{ padding: "3px 0px 10px" }}>
                Date
              </Typography>
              <DateTimePicker
                name="date"
                size="small"
                className={classes.textField}
                value={moment().format("YYYY-MM-DD")}
                inputProps={{
                  min: moment().add(1).format("YYYY-MM-DD"),
                }}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className="space-l-r">
              <Typography variant="h5" sx={{ padding: "3px 0px 10px" }}>
                Valid Until
              </Typography>
              <DateTimePicker
                inputProps={{
                  min: moment().add(1, "days").toDate(),
                  max: moment().add(1, "years").toDate(),

                  placeholder: "Please Select",
                }}
                value={
                  JobDetailsData[0].isDraft
                    ? values.validDate
                    : new Date(values.validDate)
                }
                minDate={moment().add(1, "days").toDate()}
                maxDate={moment().add(1, "years").toDate()}
                onChange={(dateString: any) => {
                  setFieldValue(
                    "validDate",
                    moment(new Date(dateString)).format("YYYY-MM-DD")
                  );
                }}
                name="validUntil"
                size="small"
                className={classes.textField}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <Typography variant="h5" sx={{ padding: "3px 0px 10px" }}>
                Quote Number
              </Typography>
              <TextfieldWrapper
                name="quoteno"
                placeholder="Quote No."
                size="small"
                inputProps={{ maxLength: 10 }}
                className={classes.textField}
              />
            </Grid>

            <div className="flex-row">
              <Grid item xs={12} sm={6} md={6} className="half-col">
                <Typography sx={{ padding: "10px 0" }} variant="h5">
                  Location/Delivery Address
                </Typography>
                <TextfieldWrapper
                  name="clientlocation"
                  size="small"
                  placeholder="Client Location"
                  inputProps={{ readOnly: true }}
                  className={classes.textField}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} className="half-col">
                <Typography sx={{ padding: "10px 0" }} variant="h5">
                  Quote Title
                </Typography>
                <TextfieldWrapper
                  name="projectname"
                  size="small"
                  placeholder="Title"
                  className={classes.textField}
                />
              </Grid>
            </div>
          </Grid>
        </Box>

        <button type="submit" style={{ display: "none" }} ref={submitRef} />
      </PaperBody>
    </Form>
  );
};

export default function QuoteWrapper({ initialObj }: { [key: string]: any }) {
  const submitRef = React.useRef(null);
  const { dispatch } = useRedux();

  React.useEffect(() => {
    dispatch({
      type: SCRATCH_TEMPLATE,
      payload: {
        clientErrors: true,
        clientDetails: getInitialValues(initialObj),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValues(initialObj)}
      validationSchema={Schema.ClientScratchSchema}
      onSubmit={(values) => {
        console.log("MMM/finalValues", values);
        dispatch({
          type: SCRATCH_TEMPLATE,
          payload: {
            clientErrors: true,
            clientDetails: { ...values },
          },
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        submitForm,
        isValid,
        dirty,
      }) => {
        const quoteAttributes = {
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          submitRef,
          isValid,
          dirty,
        };
        return <QuoteForm {...quoteAttributes} />;
      }}
    </Formik>
  );
}
