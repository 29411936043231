/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCallback, useEffect, useState } from "react";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Button, Divider, Link, Typography, Tooltip, Avatar } from "@mui/material";
import CommonFunction, {
  getFileType,
  capitalizeFirstLetter,
  getDatesInRangeM,
} from "../../../Utils/commonFunctions";
import { externalTradeDetailsReducer } from "../../MaintenanceSupport/reducer";
import BuildButton from "../../../components/button/buttonBuild";
import LocalImages from "../../../Utils/localImages";
import BuildQuoteModal from "./modalBuildQuote";
import { useStyles } from "./spBuildsQuotesCss";
import { ReducersModel } from "../../../model";
import {
  companyJobDetails,
  getComment,
  addComments,
  addCommentsImages,
  commentImages,
  getExternalTradeDetails,
} from "./action";
import { theme } from "../../../theme/theme";
import PaperBody from "../PaperBody";
import PreciseRating from "./rating";
import { assignJobsReduserState } from "../../dashboard/dashboardPage/reducer";
import Utils from "../../../Utils";
import Title from "../Title";
import MobileResponsive from "./mobileResponsive/mobileResponsiveIndex";
import EllipsisToolTip from "ellipsis-tooltip-react-chan";
import SpOwnModal from "../../SpOwn/SpCreate/SpOwnModal";
import NormalButton from "../../../components/button/NormalButton";
import ModalBox from "../../../components/modal/modalBox";
import ExternalTeamModal from "../../dashboard/dashboardPage/Requests/maintainance/assignModalExternal";
import AssignModalMSExternal from "../../dashboard/dashboardPage/Requests/maintainance/assignMSExternal";
import AssignModal from "../../dashboard/dashboardPage/Requests/maintainance/assignModal";
import AssignModalMS from "../../dashboard/dashboardPage/Requests/maintainance/assignMS";
import AssignModalMSR from "../../dashboard/dashboardPage/Requests/maintainance/assignJobMsr";
import ImageUploading from "react-images-uploading";
import { requestType } from "../../../Utils/data";
import moment from "moment-timezone";
import { getDatesInRange } from "../../../Utils/commonFunctions";
import EnquireModal from "./enquireModal";
import { isExternal } from "util/types";
import { daysInWeek } from "date-fns";
import { useOnScreen } from "../../../hooks/useOnScreen";

type AWSURL = string;

const awsUrl: AWSURL = "https://d1q3b2dp06ua7r.cloudfront.net/";

const SpBuildsQuotes = () => {
  const classes = useStyles();
  const themeBreak = useTheme();
  const [images, setImages] = useState([]);
  const maxNumber = 3;
  const onChange = (imageList: any) => {
    // data for submit
    setImages(imageList);
  };
  const mobileView = useMediaQuery(themeBreak.breakpoints.down("md"));
  const dispatch = useDispatch();

  const { state: bqmodalOpen } = useLocation();

  let { id } = useParams<{ id: string }>();

  const [openSuccess, setOpenSuccess] = useState<boolean>(Boolean(bqmodalOpen));
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen1, setModalOpen1] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);
  const [modalOpen5, setModalOpen5] = useState(false);
  const [jobData, setJobData] = useState<any>(null);
  const [jobId, setJobId] = useState("");
  const [spOwnModalOpen, setSpOwnModalOpen] = useState<boolean>(false);
  const [message, setMessage] = useState("");

  const handleCloseSuccess = useCallback(() => {
    setOpenSuccess(false);
  }, []);

  const handleChange = (event: any) => {
    setMessage(event.target.value);
  };

  const enquireHandler = (id: string) => {
    localStorage.setItem("clientId", id);
    setModalOpen(true);
  };

  useEffect(() => {
    dispatch(companyJobDetails(id));
  }, [dispatch, id]);

  const { JobDetailsData } = useSelector(
    (state: ReducersModel) => state.jobDetailsModelReducer
  );
  const { externalTradeDetails } = useSelector(
    (state: ReducersModel) => state.externalTradeDetailsReducer
  );

  const { userId, invitedEmployee, invitedEmployee2, tableDataMs } =
    useSelector(assignJobsReduserState);

  useEffect(() => {
    setJobId(JobDetailsData[0]?.jobId);
    dispatch(getExternalTradeDetails(localStorage.getItem("jobId"), id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { CommentsData } = useSelector(
    (state: ReducersModel) => state.commentsModelReducer
  );

  const commenType =
    JobDetailsData[0]?.status === "REQUESTED" ||
      JobDetailsData[0]?.status === "PENDING"
      ? "PRE_COMMENT"
      : "COMMENT";

  console.log(
    "JobDetailsData[0] && JobDetailsData[0]?.jobId",
    JobDetailsData[0] && JobDetailsData[0]?.jobId
  );

  const jobId1 = JobDetailsData[0] && JobDetailsData[0]?.jobId;

  useEffect(() => {
    if (jobId1) {
      dispatch(
        getComment(
          jobId1,
          JobDetailsData[0]?.status === "REQUESTED" ||
            JobDetailsData[0]?.status === "PENDING"
            ? ""
            : id,
          commenType
        )
      );
    }
  }, [dispatch, id, commenType, jobId1]);

  const comment = () => {
    dispatch(
      addComments(
        message,
        JobDetailsData[0]?.jobId,
        JobDetailsData[0]?.status === "REQUESTED" ||
          JobDetailsData[0]?.status === "PENDING"
          ? ""
          : id,
        commenType
      )
    );
    setMessage("");
    setImages([]);
  };

  const commentWithImages = () => {
    dispatch(
      addCommentsImages(
        images,
        message,
        JobDetailsData[0]?.jobId,
        JobDetailsData[0]?.status === "REQUESTED" ||
          JobDetailsData[0]?.status === "PENDING"
          ? ""
          : id,
        commenType
      )
    );
    setMessage("");
    setImages([]);
  };

  const imageComment = () => {
    dispatch(
      commentImages(
        images,
        JobDetailsData[0]?.jobId,
        JobDetailsData[0]?.status === "REQUESTED" ||
          JobDetailsData[0]?.status === "PENDING"
          ? ""
          : id,
        commenType
      )
    );
    setMessage("");
    setImages([]);
  };

  const history = useHistory();

  const isDraftRoute = (item: any) => {
    if (item?.quoteSelection === "UPLOAD") {
      history.push(Utils.Pathname.buildQuoteOwnPreview, {
        jobId: item?._id,
        quoteSelection: "UPLOAD",
      });
    }

    if (item?.quoteSelection === "TEMPLATE") {
      history.push({
        pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE,
        state: { id: item?._id, quoteSelection: "TEMPLATE" },
      });
    }

    if (item?.quoteSelection === "SCRATCH") {
      history.push({
        pathname: Utils.Pathname.BUILD_QUOTE_SCRATCH + `/${id}`,
        state: { id: item?._id, quoteSelection: "SCRATCH" },
      });

      window.location.reload();
    }
  };

  const setImageIconOrUrl = (url: string): string => {
    const API_URL = Utils.Constants.API_URL;
    if (getFileType(url) === "doc") {
      return LocalImages.DocIcon;
    }
    if (getFileType(url) === "pdf") {
      return LocalImages.PdfIcon;
    }
    if (getFileType(url) === "xlsx") {
      return LocalImages.ExcelIcon;
    }
    if (getFileType(url) === "jpeg" || getFileType(url) === "jpg") {
      return LocalImages.JpegIcon;
    }
    if (getFileType(url) === "png") {
      return LocalImages.PngIcon;
    }
    return API_URL + url; //by default if you have to set
  };

  const convertToKB = (num: any) => {
    if (typeof num === "number") {
      return `${parseFloat(`${(num * 1024) / 100}`).toFixed(2)} KB`;
    } else {
      return "0 KB";
    }
  };

  const navigateToBQPreview = (item: any) => {
    if (item?.quoteSelection === "UPLOAD") {
      history.push(Utils.Pathname.buildQuoteOwnPreview, {
        jobId: item?._id || "",
        quoteSelection: "UPLOAD",
      });
    }
    if (item?.quoteSelection === "TEMPLATE") {
      history.push({
        pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE_PREVIEW,
        state: {
          id: item?._id,
          quoteSelection: "TEMPLATE",
          status: true,
        },
      });
    }

    if (item?.quoteSelection === "SCRATCH") {
      history.push({
        pathname: Utils.Pathname.BUILD_QUOTE_SCRATCH_PREVIEW + `/${id}`,
        state: {
          id: item?._id,
          quoteSelection: "SCRATCH",
          status: true,
        },
      });
    }
  };
  const underDev = () => {
    history.push(Utils.Pathname.NAVBAR.AsCompany.roster);
  };
  const quoteAssessment = () => {
    history.push(`${Utils.Pathname.QUOTEASSESSMENT}/${id}`);
  };

  const days: any = [];
  const recurringDays: any = [];

  const compareFn = (a: any, b: any) => (a > b ? 1 : 0);

  const handleAssign2 = (el: { [key: string]: any }) => {
    console.log("IMHERE");
    if (requestType[el?.requestType as string] === 0) {
      if (el?.job_detail?.timeFrame[0]?.recurringShift === false) {
        let dateData = [];
        let dateTest = [];
        let dateDataUTC = [];
        let nonRecurringList = [];
        let dateList = [];
        let tradeList = [];
        let time = [];
        // let searchList = [];

        if (
          moment(new Date(el?.job_detail?.timeFrame[0]?.startDate)) ===
          moment(new Date(el?.job_detail?.timeFrame[0]?.endDate))
        ) {
          dateList.push(
            moment(new Date(el?.job_detail?.timeFrame[0]?.startDate)).format(
              "DD/MM/YYYY"
            )
          );
        } else {
          dateList.push(
            moment(new Date(el?.job_detail?.timeFrame[0]?.startDate)).format(
              "DD/MM/YYYY"
            )
          );
        }
        for (let i = 0; i < el?.job_detail?.timeFrame?.length; i++) {
          time.push(
            `${`${CommonFunction.getTime(
              el?.job_detail?.timeFrame[i]?.startDate
            )}`}-${`${CommonFunction.getTime(
              el?.job_detail?.timeFrame[i]?.endDate
            )}`}`
          );
        }
        console.log(time, "time");

        for (let i = 0; i < el?.job_detail?.tradeType?.length; i++) {
          for (let j = 0; j < el?.job_detail?.tradeType[i]?.qty; j++) {
            tradeList.push(el?.job_detail?.tradeType[i]?.type);
          }
        }

        for (let i = 0; i < el?.allDateArray[0]?.date?.length; i++) {
          dateTest.push(el?.allDateArray[0]?.date[i]);
        }

        if (
          el?.job_detail?.timeFrame[0]?.shiftType === "WEEKEND" ||
          el?.job_detail?.timeFrame[0]?.shiftType === "PUBLIC_HOLIDAY"
        ) {
          for (let i = 0; i < dateTest.length; i++) {
            dateData.push({
              date: dateTest[i],
              shift: el?.job_detail?.timeFrame[0].shiftType,
              time: time[0],
            });
          }
        } else {
          for (let i = 0; i < el?.job_detail?.timeFrame.length; i++) {
            dateData.push({
              date: getDatesInRangeM(
                new Date(el?.job_detail?.timeFrame[i]?.startDate),
                new Date(el?.job_detail?.timeFrame[i]?.endDate)
              ),
              shift: el?.job_detail?.timeFrame[i].shiftType,
              time: time[i],
              dateTime: el?.allDateArray[0]?.date,
            });
          }
        }

        for (let i = 0; i < el?.job_detail?.timeFrame.length; i++) {
          dateDataUTC.push({
            date: getDatesInRangeM(
              el?.job_detail?.timeFrame[i]?.startDate,
              el?.job_detail?.timeFrame[i]?.endDate
            ),
          });
        }

        if (
          el?.job_detail?.timeFrame[0]?.shiftType === "WEEKEND" ||
          el?.job_detail?.timeFrame[0]?.shiftType === "PUBLIC_HOLIDAY"
        ) {
          for (let k = 0; k < dateData.length; k++) {
            for (let i = 0; i < tradeList.length; i++) {
              nonRecurringList.push({
                tradeType: tradeList[i],
                date: dateData[k]?.date,
                shift: dateData[k]?.shift,
                time: dateData[k]?.time,
                employee: null,
              });
            }
          }
        } else {
          console.log(dateData, "date");
          for (let k = 0; k < dateData?.length; k++) {
            for (let j = 0; j < dateData[k]?.dateTime.length; j++) {
              for (let i = 0; i < tradeList.length; i++) {
                nonRecurringList.push({
                  tradeType: tradeList[i],
                  date: dateData[k]?.date[j],
                  dateTime: dateData[k]?.dateTime[j],
                  shift: dateData[k]?.shift,
                  time: dateData[k]?.time,
                  employee: null,
                });
              }
            }
          }
        }
        dispatch({
          type: Utils.ActionName.ASSIGN_REQUEST_TYPE,
          payload: {
            nonRecurringList,
          },
        });

        setModalOpen5(true);
        console.log(nonRecurringList, "AssignMS1");
      } else {
        let monday: any = [];
        let tuesday: any = [];
        let wednesday: any = [];
        let thursday: any = [];
        let friday: any = [];
        let saturday: any = [];
        let sunday: any = [];
        let daysData: any = [];
        let tableData2: any = [];
        let daySlots: any = [];
        let tradeList: any = [];
        // let searchList: any = [];

        daysData.push(el?.job_detail?.timeFrame[0]?.days);

        daySlots.push(el?.daySlots[0]);

        for (let i = 0; i < el?.tradeType?.length; i++) {
          for (let j = 0; j < el?.tradeType[i]?.qty; j++) {
            tradeList.push(el?.tradeType[i]?.type);
          }
        }

        //For MSR
        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["friday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              friday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["friday"][k]?.shiftType,
                employee: null,
              });
            }
          });
        }
        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["monday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              monday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["monday"][k]?.shiftType,
                employee: null,
              });
            }
          });
        }
        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["tuesday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              tuesday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["tuesday"][k]?.shiftType,
                employee: null,
              });
            }
          });
        }

        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["wednesday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              wednesday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["wednesday"][k]?.shiftType,
                employee: null,
              });
            }
          });
        }
        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["thursday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              thursday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["thursday"][k]?.shiftType,
                employee: null,
              });
            }
          });
        }

        for (let k = 0; k < daySlots[0]["saturday"]?.length; k++) {
          daySlots[0]["saturday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              saturday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["saturday"][k]?.shiftType,
                employee: null,
              });
            }
          });
        }

        for (let k = 0; k < daySlots[0]["sunday"]?.length; k++) {
          daySlots[0]["sunday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              sunday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["sunday"][k]?.shiftType,
                employee: null,
              });
            }
          });
        }

        tableData2.push(sunday);
        tableData2.push(monday);
        tableData2.push(tuesday);
        tableData2.push(wednesday);
        tableData2.push(thursday);
        tableData2.push(friday);
        tableData2.push(saturday);

        let dList: any = [];
        let recurringList: any = [];

        tableData2 &&
          tableData2?.map((item: any) => {
            dList.push(item);
          });

        dList &&
          dList?.map((item: any) => {
            if (item?.length) {
              recurringList.push(item);
            } else {
              recurringList.push([]);
            }
          });

        dispatch({
          type: Utils.ActionName.ASSIGN_REQUEST_TYPE,
          payload: {
            sunday,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            recurringList,
          },
        });

        setModalOpen3(true);
      }
    } else if (
      requestType[el?.requestType as string] === 1 ||
      requestType[el?.requestType as string] === 2
    ) {
      let tradeList = [];
      for (let i = 0; i < el?.tradeType?.length; i++) {
        for (let j = 0; j < el?.tradeType[i]?.qty; j++) {
          tradeList.push({
            trade: el?.tradeType[i]?.type,
            employee: null,
          });
        }
      }
      dispatch({
        type: Utils.ActionName.ASSIGN_REQUEST_TYPE,
        payload: {
          tradeList,
        },
      });
      setModalOpen4(true);
    }
    setJobData(el);
  };
  const options = {
    effect: "solid",
    place: "top",
  };

  const handleAssign = (el: { [key: string]: any }) => {
    if (requestType[el?.requestType as string] === 0) {
      if (el?.job_detail?.timeFrame[0]?.recurringShift === false) {
        let dateData = [];
        let dateTest = [];
        let dateDataUTC = [];
        let nonRecurringList = [];
        let dateList = [];
        let tradeList = [];
        let time = [];
        // let searchList = [];

        if (
          moment(new Date(el?.job_detail?.timeFrame[0]?.startDate)) ===
          moment(new Date(el?.job_detail?.timeFrame[0]?.endDate))
        ) {
          dateList.push(
            moment(new Date(el?.job_detail?.timeFrame[0]?.startDate)).format(
              "DD/MM/YYYY"
            )
          );
        } else {
          dateList.push(
            moment(new Date(el?.job_detail?.timeFrame[0]?.startDate)).format(
              "DD/MM/YYYY"
            )
          );
        }
        for (let i = 0; i < el?.job_detail?.timeFrame?.length; i++) {
          time.push(
            `${`${CommonFunction.getTime(
              el?.job_detail?.timeFrame[i]?.startDate
            )}`}-${`${CommonFunction.getTime(
              el?.job_detail?.timeFrame[i]?.endDate
            )}`}`
          );
        }
        console.log(time, "time");

        for (let i = 0; i < el?.job_detail?.tradeType?.length; i++) {
          for (let j = 0; j < el?.job_detail?.tradeType[i]?.qty; j++) {
            tradeList.push(el?.job_detail?.tradeType[i]?.type);
          }
        }

        for (let i = 0; i < el?.allDateArray[0]?.date?.length; i++) {
          dateTest.push(el?.allDateArray[0]?.date[i]);
        }

        if (
          el?.job_detail?.timeFrame[0]?.shiftType === "WEEKEND" ||
          el?.job_detail?.timeFrame[0]?.shiftType === "PUBLIC_HOLIDAY"
        ) {
          for (let i = 0; i < dateTest.length; i++) {
            dateData.push({
              date: dateTest[i],
              shift: el?.job_detail?.timeFrame[0].shiftType,
              time: time[0],
            });
          }
        } else {
          for (let i = 0; i < el?.job_detail?.timeFrame.length; i++) {
            dateData.push({
              date: getDatesInRangeM(
                new Date(el?.job_detail?.timeFrame[i]?.startDate),
                new Date(el?.job_detail?.timeFrame[i]?.endDate)
              ),
              shift: el?.job_detail?.timeFrame[i].shiftType,
              time: time[i],
              dateTime: el?.allDateArray[0]?.date,
            });
          }
          console.log(dateData, "date");
        }

        for (let i = 0; i < el?.job_detail?.timeFrame.length; i++) {
          dateDataUTC.push({
            date: getDatesInRangeM(
              el?.job_detail?.timeFrame[i]?.startDate,
              el?.job_detail?.timeFrame[i]?.endDate
            ),
          });
        }

        if (
          el?.job_detail?.timeFrame[0]?.shiftType === "WEEKEND" ||
          el?.job_detail?.timeFrame[0]?.shiftType === "PUBLIC_HOLIDAY"
        ) {
          for (let k = 0; k < dateData.length; k++) {
            for (let i = 0; i < tradeList.length; i++) {
              nonRecurringList.push({
                tradeType: tradeList[i],
                date: dateData[k]?.date,
                shift: dateData[k]?.shift,
                time: dateData[k]?.time,
                employee: null,
              });
            }
          }
        } else {
          for (let k = 0; k < dateData.length; k++) {
            for (let j = 0; j < dateData[k]?.date.length; j++) {
              for (let i = 0; i < tradeList.length; i++) {
                nonRecurringList.push({
                  tradeType: tradeList[i],
                  date: dateData[k]?.date[j],
                  dateTime: dateData[k]?.dateTime[j],
                  shift: dateData[k]?.shift,
                  time: dateData[k]?.time,
                  employee: null,
                });
              }
            }
          }
        }
        dispatch({
          type: Utils.ActionName.ASSIGN_REQUEST_TYPE,
          payload: {
            nonRecurringList,
          },
        });

        setModalOpen2(true);
        console.log(nonRecurringList, "AssignMS1");
      } else {
        let monday: any = [];
        let tuesday: any = [];
        let wednesday: any = [];
        let thursday: any = [];
        let friday: any = [];
        let saturday: any = [];
        let sunday: any = [];
        let daysData: any = [];
        let tableData2: any = [];
        let daySlots: any = [];
        let tradeList: any = [];
        let time: any = [];
        // let searchList: any = [];

        daysData.push(el?.job_detail?.timeFrame[0]?.days);

        daySlots.push(el?.daySlots[0]);

        for (let i = 0; i < el?.job_detail?.tradeType?.length; i++) {
          for (let j = 0; j < el?.job_detail?.tradeType[i]?.qty; j++) {
            tradeList.push(el?.job_detail?.tradeType[i]?.type);
          }
        }
        for (let i = 0; i < el?.job_detail?.timeFrame?.length; i++) {
          time.push(
            `${`${CommonFunction.getTime(
              el?.job_detail?.timeFrame[i]?.startDate
            )}`}-${`${CommonFunction.getTime(
              el?.job_detail?.timeFrame[i]?.endDate
            )}`}`
          );
        }

        //For MSR
        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["friday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              friday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["friday"][k]?.shiftType,
                time: time[0],
                employee: null,
              });
            }
          });
        }
        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["monday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              monday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["monday"][k]?.shiftType,
                time: time[0],
                employee: null,
              });
            }
          });
        }
        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["tuesday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              tuesday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["tuesday"][k]?.shiftType,
                time: time[0],
                employee: null,
              });
            }
          });
        }

        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["wednesday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              wednesday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["wednesday"][k]?.shiftType,
                time: time[0],
                employee: null,
              });
            }
          });
        }
        for (let k = 0; k < daySlots[0]["thursday"]?.length; k++) {
          daySlots[0]["thursday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              thursday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["thursday"][k]?.shiftType,
                time: time[0],
                employee: null,
              });
            }
          });
        }

        for (let k = 0; k < daySlots[0]["saturday"]?.length; k++) {
          daySlots[0]["saturday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              saturday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["saturday"][k]?.shiftType,
                time: time[0],
                employee: null,
              });
            }
          });
        }

        for (let k = 0; k < daySlots[0]["sunday"]?.length; k++) {
          daySlots[0]["sunday"][k]?.date?.map((i: any) => {
            for (let j = 0; j < tradeList?.length; j++) {
              sunday?.push({
                tradeType: tradeList[j],
                date: i,
                shift: daySlots[0]["sunday"][k]?.shiftType,
                time: time[0],
                employee: null,
              });
            }
          });
        }

        tableData2.push(sunday);
        tableData2.push(monday);
        tableData2.push(tuesday);
        tableData2.push(wednesday);
        tableData2.push(thursday);
        tableData2.push(friday);
        tableData2.push(saturday);

        let dList: any = [];
        let recurringList: any = [];

        tableData2 &&
          tableData2?.map((item: any) => {
            dList.push(item);
          });

        dList &&
          dList?.map((item: any) => {
            if (item?.length) {
              recurringList.push(item);
            } else {
              recurringList.push([]);
            }
          });

        dispatch({
          type: Utils.ActionName.ASSIGN_REQUEST_TYPE,
          payload: {
            sunday,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            recurringList,
          },
        });

        setModalOpen3(true);
      }
    } else if (
      requestType[el?.requestType as string] === 1 ||
      requestType[el?.requestType as string] === 2
    ) {
      let tradeList = [];
      for (let i = 0; i < el?.job_detail?.tradeType?.length; i++) {
        for (let j = 0; j < el?.job_detail?.tradeType[i]?.qty; j++) {
          tradeList.push({
            trade: el?.job_detail?.tradeType[i]?.type,
            employee: null,
          });
        }
      }
      dispatch({
        type: Utils.ActionName.ASSIGN_REQUEST_TYPE,
        payload: {
          tradeList,
        },
      });
      setModalOpen1(true);
    }
    setJobData(el);
  };

  const { inView, elementRef } = useOnScreen()

  localStorage.setItem("quoteSelection", JobDetailsData[0]?.quoteSelection);

  return (
    <>
      <ThemeProvider theme={theme}>
        {JobDetailsData.length > 0 &&
          JobDetailsData?.map((item: any, idx: number) => {
            return (
              <Box key={idx} id="job_details">
                {mobileView ? (
                  <MobileResponsive data={JobDetailsData} />
                ) : (
                  <Box key={idx}>
                    <Title>
                      <Typography variant="h4" color="text.secondary" >
                        Job Details
                      </Typography>
                    </Title>
                    <PaperBody>
                      <Box sx={{ display: "flex" }}>
                        <Box className={classes.leftdescription}>
                          <Box sx={{ padding: "24px" }}>
                            <Box sx={{ padding: "0px 0px 8px 0px " }}>
                              {item?.requestType === "PROJECT_SUPPORT" ? (
                                <Typography variant="h5" color="text.secondary">
                                  {item?.job_detail.projectName}
                                </Typography>
                              ) : (
                                <Typography variant="h5" color="text.secondary">
                                  Request Title
                                </Typography>
                              )}
                            </Box>

                            <Typography variant="h6" color="text.secondary">
                              {capitalizeFirstLetter(
                                item.requestType.replace("_", " ")
                              )}{" "}
                              • Posted{" "}
                              {CommonFunction.getNotificationTimeStatus(
                                item?.createdAt
                              )}{" "}
                              ago by{" "}
                              {item?.isExternal === true
                                ? item?.team_up_company_detail[0]?.companyDetail
                                  ?.businessName
                                : item?.job_detail?.clientDetail?.businessName}
                            </Typography>
                          </Box>
                          <Divider />
                          <Box sx={{ padding: "24px 0px 8px 24px " }}>
                            <Typography variant="h5" color="text.secondary">
                              Details
                            </Typography>
                          </Box>

                          <Box sx={{ padding: "0px 0px 0px 24px " }}>
                            {item?.requestType === "PROJECT_SUPPORT" ? (
                              <Box
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <img
                                  src={LocalImages.clockIcon}
                                  alt={"Clock"}
                                />
                                <Typography marginLeft="20px">
                                  {moment(
                                    new Date(
                                      item?.job_detail?.estimateStartDate
                                    )
                                  ).format("DD/MM/YYYY")}{" "}
                                  -{" "}
                                  {moment(
                                    new Date(item?.job_detail?.estimateEndDate)
                                  )
                                    .subtract(1, "day")
                                    .format("DD/MM/YYYY")}
                                </Typography>
                                <Typography marginLeft="20px">
                                  ~
                                  {getDatesInRange(
                                    new Date(
                                      item?.job_detail?.estimateStartDate
                                    ),
                                    new Date(item?.job_detail?.estimateEndDate)
                                  )}{" "}
                                </Typography>
                              </Box>
                            ) : item?.requestType === "MAINTENANCE_SERVICE" &&
                              (item?.allDateArray[0]?.shiftType ===
                                "PUBLIC_HOLIDAY" ||
                                item?.allDateArray[0]?.shiftType ===
                                "WEEKEND") ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-evenly",
                                  width: "40%",
                                  marginRight: "30px",
                                }}
                              >
                                <Typography marginLeft="20px" marginTop="2px">
                                  <img
                                    src={LocalImages.clockIcon}
                                    alt={"Clock"}
                                  />
                                </Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {item?.allDateArray[0]?.date?.map(
                                    (date: any) => {
                                      return (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            width: "100%",
                                          }}
                                        >
                                          <Typography>
                                            {moment(new Date(date)).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </Typography>
                                          <Typography
                                            sx={{ marginLeft: "10px" }}
                                          >
                                            {`${` (${CommonFunction.getTime(
                                              item?.job_detail?.timeFrame[0]
                                                ?.startDate
                                            )}`}-${`${CommonFunction.getTime(
                                              item?.job_detail?.timeFrame[0]
                                                ?.endDate
                                            )})`}`}
                                          </Typography>
                                        </Box>
                                      );
                                    }
                                  )}
                                </Box>
                              </Box>
                            ) : item?.requestType === "MAINTENANCE_SERVICE" &&
                              item?.job_detail?.timeFrame[0]?.recurringShift ===
                              false ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "50%",
                                }}
                              >
                                <Typography marginLeft="20px" marginTop="2px">
                                  <img
                                    src={LocalImages.clockIcon}
                                    alt={"Clock"}
                                  />
                                </Typography>
                                <Typography>
                                  {moment(
                                    new Date(
                                      item?.job_detail?.timeFrame[0]?.startDate
                                    )
                                  ).format("DD/MM/YYYY")}{" "}
                                  -{" "}
                                  {moment(
                                    new Date(
                                      item?.job_detail?.timeFrame[0]?.endDate
                                    )
                                  ).format("DD/MM/YYYY")}
                                </Typography>
                                <Typography>
                                  {`${` (${CommonFunction.getTime(
                                    item?.job_detail?.timeFrame[0]?.startDate
                                  )}`}-${`${CommonFunction.getTime(
                                    item?.job_detail?.timeFrame[0]?.endDate
                                  )})`}`}
                                </Typography>
                              </Box>
                            ) : item?.requestType === "MAINTENANCE_SERVICE" &&
                              item?.job_detail?.timeFrame[0]?.recurringShift ===
                              true ? (
                              <>
                                <Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <img
                                      src={LocalImages.clockIcon}
                                      alt={"Clock"}
                                      style={{
                                        marginBottom: "20px",
                                        paddingRight: "20px",
                                      }}
                                    />
                                    {item?.job_detail?.timeFrame.map(
                                      (i: any) => {
                                        days.push(
                                          i?.days.sort(function (
                                            a: any,
                                            b: any
                                          ) {
                                            return a - b;
                                          })
                                        );
                                        if (days[0][0] === 0) {
                                          days[0] = i?.days
                                            .slice(1, days[0].length)
                                            .concat(0);
                                          // days.slice(1).concat(0);
                                        }
                                        console.log(days[0], "days");
                                        return (
                                          <Box>
                                            {days[0].map((d: any) => {
                                              return (
                                                <Box>
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      flexDirection: "row",
                                                    }}
                                                  >
                                                    <Typography
                                                      sx={{
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {d === 1
                                                        ? "Monday"
                                                        : d === 2
                                                          ? "Tuesday"
                                                          : d === 3
                                                            ? "Wednesday"
                                                            : d === 4
                                                              ? "Thursday"
                                                              : d === 5
                                                                ? "Friday"
                                                                : d === 6
                                                                  ? "Saturday"
                                                                  : d === 0
                                                                    ? "Sunday"
                                                                    : ""}
                                                    </Typography>
                                                    <Typography
                                                      sx={{
                                                        marginLeft: "10px",
                                                      }}
                                                    >
                                                      {`${` (${CommonFunction.getTime(
                                                        i?.startDate
                                                      )}`}-${`${CommonFunction.getTime(
                                                        i?.endDate
                                                      )})`}`}
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                              );
                                            })}
                                          </Box>
                                        );
                                      }
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      marginTop: "2%",
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <img
                                        src={LocalImages.Calender}
                                        alt={"Clock"}
                                        style={{
                                          marginBottom: "20px",
                                          paddingRight: "20px",
                                        }}
                                      />

                                      <Typography>
                                        Starting From (
                                        {moment(
                                          new Date(
                                            item?.job_detail?.estimateStartDate
                                          )
                                        ).format("DD/MM/YYYY")}
                                        )
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <img
                                        src={LocalImages.Calender}
                                        alt={"Clock"}
                                        style={{
                                          marginBottom: "20px",
                                          paddingRight: "20px",
                                        }}
                                      />

                                      <Typography>
                                        Ending (
                                        {moment(
                                          new Date(
                                            item?.job_detail?.estimateEndDate
                                          )
                                        ).format("DD/MM/YYYY")}
                                        )
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </>
                            ) : item?.requestType === "RFQ" ? (
                              ""
                            ) : (
                              "ASAP"
                            )}

                            <Typography
                              marginTop={"10px"}
                              variant="h6"
                              color="text.secondary"
                            >
                              {item?.job_detail?.projectDescription}
                            </Typography>
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            <Divider />
                          </Box>
                          <Box sx={{ padding: "24px" }}>
                            <Typography variant="h5" color="text.secondary">
                              Attachments
                            </Typography>
                            <Box className={classes.fileUpload}>
                              <Box sx={{ mt: 2, mr: 2 }}>
                                <img
                                  src={`${LocalImages.attachmentIcon}?w=248&fit=crop&auto=format`}
                                  alt="logo not found"
                                  height="14.21px"
                                  width="14.21px"
                                />
                              </Box>
                              {item?.job_detail?.mediaFile.length > 0 ? (
                                item?.job_detail?.mediaFile.map(
                                  (img: any, index: any) => {
                                    return (
                                      <Box
                                        key={index}
                                        className={classes.boxContainerFile}
                                      >
                                        <Box
                                          className={classes.boxInnerContainer}
                                        >
                                          <img
                                            src={setImageIconOrUrl(img?.url)}
                                            alt="img not found"
                                            height="30px"
                                            width="25px"
                                          />
                                        </Box>
                                        <Box
                                          sx={{
                                            flexDirection: "column",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {item?.job_detail?.mediaFile.length >
                                            0 ? (
                                            <>
                                              <Button>
                                                <Link
                                                  href={img?.url}
                                                  target="_blank"
                                                  underline="none"
                                                  color="secondary"
                                                >
                                                  <EllipsisToolTip
                                                    style={{ width: "100px" }}
                                                  >
                                                    {decodeURI(
                                                      img?.url
                                                    ).replace(awsUrl, "")}
                                                  </EllipsisToolTip>
                                                </Link>
                                              </Button>
                                              <Typography paddingLeft="1rem">
                                                {convertToKB(img?.size * 100)}
                                              </Typography>
                                            </>
                                          ) : (
                                            <Typography>No Image</Typography>
                                          )}
                                        </Box>

                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <ArrowDropDownIcon />
                                        </Box>
                                      </Box>
                                    );
                                  }
                                )
                              ) : (
                                <Typography marginTop={"15px"} variant="h3">
                                  No File Attached
                                </Typography>
                              )}
                            </Box>
                          </Box>
                          <Box>
                            <Divider />
                          </Box>
                          {(item?.requestType === "EMERGENCY_CALLOUT" ||
                            item?.requestType === "PROJECT_SUPPORT" ||
                            item?.requestType === "MAINTENANCE_SERVICE") &&
                            item?.invitedEmployee ? (
                            <Box sx={{ padding: "3% " }}>
                              <Typography
                                variant="h5"
                                color="text.secondary"
                                sx={{ marginBottom: "2%" }}
                                id="Comm"
                              >
                                Comments ({CommentsData.length})
                              </Typography>
                              <Typography variant="h5" color="text.secondary">
                                {CommentsData && CommentsData.length > 0 ? (
                                  CommentsData.map((el: any) => {
                                    return (
                                      <>
                                        <Box sx={{ flexDirection: "row" }}>
                                          <Box
                                            sx={{
                                              // width: "30%",
                                              display: "flex",
                                              justifyContent: "normal",
                                              // justifyContent: "space-between",
                                            }}
                                          >
                                            {el?.user_detail?.companyDetail
                                              ?.businessProfilePicture ? (
                                              <img
                                                src={`${el?.user_detail?.companyDetail?.businessProfilePicture}`}
                                                alt="clock img not found"
                                                width="32px"
                                                height="32px"
                                                loading="lazy"
                                                style={{
                                                  borderRadius: "50%",
                                                  marginRight: "2%",
                                                  marginTop: "-2px",
                                                }}
                                              />
                                            ) : el?.user_detail?.profilePicture ? (
                                              <img
                                                src={`${el?.user_detail?.profilePicture}`}
                                                alt="clock img not found"
                                                width="32px"
                                                height="32px"
                                                loading="lazy"
                                                style={{
                                                  borderRadius: "50%",
                                                  marginRight: "2%",
                                                  marginTop: "-2px",
                                                }}
                                              />
                                            ) : (
                                              <Avatar sx={{
                                                height: "32px", width: "32px", borderRadius: "50%",
                                                marginRight: "2%",
                                                marginTop: "-2px",
                                              }} alt="" />
                                              // <img
                                              //   src={`${LocalImages.AvatarIcon}`}
                                              //   alt="clock img not found"
                                              //   width="32px"
                                              //   height="32px"
                                              //   loading="lazy"
                                              //   style={{
                                              //     borderRadius: "50%",
                                              //     marginRight: "2%",
                                              //     marginTop: "-2px",
                                              //   }}
                                              // />
                                            )}
                                            <Typography>{el?.user_detail?.name}</Typography>
                                            <Typography
                                              sx={{ color: "#828282", marginLeft: "2%" }}
                                            >
                                              {CommonFunction.getNotificationTimeStatus(
                                                el?.createdAt
                                              )}{" "}
                                              ago
                                            </Typography>
                                          </Box>
                                          <Box sx={{ mt: "2%" }}>
                                            {el?.image.length > 0
                                              ? el?.image?.map((i: any) => {
                                                return (
                                                  <img
                                                    key={i}
                                                    width={"327px"}
                                                    height={"245px"}
                                                    src={i}
                                                    alt="comment"
                                                  />
                                                );
                                              })
                                              : ""}
                                          </Box>
                                          <Typography
                                            sx={{
                                              padding: "0% 0% 2% 0%",
                                              color: "#424546",
                                            }}
                                          >
                                            {el?.comment}
                                          </Typography>
                                        </Box>
                                      </>
                                    );
                                  })
                                ) : (
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      width: "100%",
                                      display: "flex",
                                      flexDirection: "column",
                                      padding: "80px 0px",
                                    }}
                                  >
                                    <img
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                      }}
                                      src={LocalImages.noComments}
                                      alt="No Comments"
                                    />
                                    <Typography>No Comments Yet</Typography>
                                  </Box>
                                )}
                              </Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-between",
                                  width: "100%",
                                  marginTop: "4%",
                                }}
                              >
                                <Box
                                  sx={{
                                    width: "2%",
                                  }}
                                >
                                  <ImageUploading
                                    multiple
                                    value={images}
                                    onChange={onChange}
                                    maxNumber={maxNumber}
                                    dataURLKey="data_url"
                                  >
                                    {({
                                      imageList,
                                      onImageUpload,
                                      onImageRemoveAll,
                                      onImageUpdate,
                                      onImageRemove,
                                      isDragging,
                                      dragProps,
                                    }) => (
                                      // write your building UI
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column-reverse",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            cursor: "pointer",
                                            position: "relative",
                                            top: "20px",
                                          }}
                                          onClick={onImageUpload}
                                          {...dragProps}
                                        >
                                          <img src={LocalImages.Add} alt="Add" />
                                        </Box>
                                        {images.length > 0 ? (
                                          <Box
                                            sx={{
                                              position: "absolute",
                                              left: "268px",
                                              width: "577px",
                                              display: "flex",
                                              flexDirection: "row",
                                              backgroundColor: "#F2F2F2",
                                              marginLeft: "-3.9%",
                                            }}
                                          >
                                            {imageList.map((image: any, index: any) => (
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                }}
                                                key={index}
                                              >
                                                <img
                                                  src={image.data_url}
                                                  alt=""
                                                  width="100"
                                                />

                                                <Box onClick={() => onImageRemove(index)}>
                                                  <img
                                                    src={LocalImages.crossIcon}
                                                    alt="CrossIcon"
                                                  />
                                                </Box>
                                              </Box>
                                            ))}
                                          </Box>
                                        ) : (
                                          ""
                                        )}
                                      </Box>
                                    )}
                                  </ImageUploading>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginLeft: "45px",
                                    paddingBottom: "30px",
                                  }}
                                >
                                  <input
                                    style={{
                                      border: "none",
                                      backgroundColor: "#F2F2F2",
                                      marginLeft: "1%",
                                      width: "577px",
                                      height: "40px",
                                      paddingLeft: "5px",
                                      fontSize: "16px",
                                      position: "relative",
                                      bottom: "10px",
                                    }}
                                    type="text"
                                    id="message"
                                    name="message"
                                    placeholder="Add a comment..."
                                    onChange={handleChange}
                                    value={message}
                                  />
                                  <NormalButton
                                    id="comment_button"
                                    ref={elementRef}
                                    sx={{
                                      width: "105px",
                                      height: "40px",
                                      backgroundColor: "#0DA4CE",
                                      color: "#fff",
                                      marginLeft: "2%",
                                      borderRadius: "0px",
                                      textTransform: "capitalize",
                                      position: "relative",
                                      bottom: "10px",
                                      "&:hover": {
                                        backgroundColor: "#097390",
                                      },
                                    }}
                                    onClick={
                                      images.length > 0 && message
                                        ? commentWithImages
                                        : images.length > 0 && !message
                                          ? imageComment
                                          : comment
                                    }
                                  >
                                    Comment
                                  </NormalButton>
                                </Box>
                              </Box>
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ borderRightWidth: 1 }}
                        />
                        <Box className={classes.rightdescription}>
                          <Box
                            sx={{
                              padding: "20px",
                            }}
                          >
                            {item?.isExternal === true &&
                              item?.status === "ASSIGNED" ? (
                              <BuildButton
                                fullWidth
                                variant="contained"
                                sx={{ backgroundColor: "#a1d5f0" }}
                              >
                                <Typography
                                  variant="h3"
                                  sx={{ color: "#0DA4CE" }}
                                >
                                  Assigned
                                </Typography>
                              </BuildButton>
                            ) : item?.requestType == "RFQ" &&
                              item?.status === "BUILD_QUOTE" ? (
                              <BuildButton
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                  setOpenSuccess(true);
                                }}
                              >
                                <Typography
                                  variant="h3"
                                  sx={{ color: "var(--white)" }}
                                >
                                  Build Quote
                                </Typography>
                              </BuildButton>
                            ) : item?.isExternal === true ? (
                              <BuildButton
                                fullWidth
                                variant="contained"
                                onClick={() => handleAssign2(item)}
                              >
                                <Typography
                                  variant="h3"
                                  sx={{ color: "var(--white)" }}
                                >
                                  Assign To...
                                </Typography>
                              </BuildButton>
                            ) : item?.isDraft ? (
                              <BuildButton
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                  isDraftRoute(item);
                                  setOpenSuccess(true);
                                }}
                              >
                                <Typography
                                  variant="h3"
                                  sx={{ color: "var(--white)" }}
                                >
                                  Continue Quote
                                </Typography>
                              </BuildButton>
                            ) : item?.requestType === "RFQ" &&
                              (item?.status === "SENT" ||
                                item?.status === "COMPLETED") ? (
                              <BuildButton
                                fullWidth
                                variant="contained"
                                onClick={() => navigateToBQPreview(item)}
                              >
                                <Typography
                                  variant="h3"
                                  sx={{ color: "var(--white)" }}
                                >
                                  View quote
                                </Typography>
                              </BuildButton>
                            ) : (item?.requestType === "EMERGENCY_CALLOUT" ||
                              item?.requestType === "PROJECT_SUPPORT" ||
                              item?.requestType === "MAINTENANCE_SUPPORT") &&
                              (item?.status === "REQUESTED" ||
                                item?.status === "ASSIGNED") &&
                              item?.invitedEmployee?.length === 0 ? (
                              <BuildButton
                                fullWidth
                                variant="contained"
                                onClick={() => handleAssign(item)}
                              >
                                <Typography
                                  variant="h3"
                                  sx={{ color: "var(--white)" }}
                                >
                                  Assign To...
                                </Typography>
                              </BuildButton>
                            ) : item?.requestType === "MAINTENANCE_SERVICE" &&
                              item?.job_detail?.timeFrame[0]?.recurringShift ===
                              false &&
                              (item?.status === "REQUESTED" ||
                                item?.status === "PENDING" ||
                                item?.status === "ASSIGNED") &&
                              item?.isTeamUp === true ? (
                              <BuildButton
                                fullWidth
                                variant="contained"
                                onClick={() => handleAssign(item)}
                              >
                                <Typography
                                  variant="h3"
                                  sx={{ color: "var(--white)" }}
                                >
                                  Assign To...
                                </Typography>
                              </BuildButton>
                            ) : item?.requestType === "MAINTENANCE_SERVICE" &&
                              item?.job_detail?.timeFrame[0]?.recurringShift ===
                              true &&
                              (item?.status === "REQUESTED" ||
                                item?.status === "ASSIGNED" ||
                                item?.status === "PENDING") &&
                              item?.isTeamUp === true ? (
                              <BuildButton
                                fullWidth
                                variant="contained"
                                onClick={() => handleAssign(item)}
                              >
                                <Typography
                                  variant="h3"
                                  sx={{ color: "var(--white)" }}
                                >
                                  Assign To...
                                </Typography>
                              </BuildButton>
                            ) : item?.status === "COMPLETED" ||
                              item?.status === "PENDING" ||
                              item?.status === "IN_PROGRESS" ? (
                              <BuildButton
                                fullWidth
                                variant="contained"
                                sx={{ backgroundColor: "#a1d5f0" }}
                              >
                                <Typography
                                  variant="h3"
                                  sx={{ color: "#0DA4CE" }}
                                >
                                  Assigned
                                </Typography>
                              </BuildButton>
                            ) : (
                              <BuildButton
                                fullWidth
                                variant="contained"
                                onClick={() => handleAssign(item)}
                              >
                                <Typography
                                  variant="h3"
                                  sx={{ color: "var(--white)" }}
                                >
                                  Assign To...
                                </Typography>
                              </BuildButton>
                            )}
                            {item?.invitedEmployee?.length > 0
                              ? item?.invitedEmployee?.map((i: any) => {
                                return (
                                  <>
                                    <Box
                                      sx={{
                                        paddingLeft: "20px !important",
                                        background:
                                          "rgba(242, 242, 242, 0.5)",
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: "20px 0",
                                      }}
                                      marginTop="5%"
                                    >
                                      {i?.profilePicture ? (
                                        <img
                                          width="24px"
                                          height="24px"
                                          style={{ borderRadius: "10px" }}
                                          src={i?.profilePicture}
                                          alt={"pp"}
                                        />
                                      ) : (
                                        <img
                                          src={LocalImages.circle}
                                          alt={"pp"}
                                        />
                                      )}
                                      <Typography
                                        sx={{
                                          fontSize: "14px",
                                          marginLeft: "10px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {i?.name}
                                      </Typography>
                                    </Box>
                                  </>
                                );
                              })
                              : ""}
                            <Box
                              sx={{ cursor: "pointer" }}
                              className={classes.messageMilk}
                              onClick={
                                () => window.location.reload()
                                // enquireHandler(
                                //   item?.job_detail?.clientDetail?.clientId
                                // )
                              }
                            >
                              <Typography
                                variant="h6"
                                color="text.secondary"
                                sx={{ padding: "5px" }}
                                className="rfqRequestType"
                              >
                                Message{" "}
                                {item?.job_detail?.clientDetail?.businessName}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                          <Box sx={{ padding: "8px" }}>
                            <Typography variant="h5" color="text.secondary">
                              About the client
                            </Typography>

                            <Box sx={{ marginTop: "12px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                              {item?.job_detail?.clientDetail
                                ?.businessProfilePicture ? (
                                <img
                                  src={
                                    item?.job_detail?.clientDetail
                                      ?.businessProfilePicture
                                  }
                                  alt="clock img not found"
                                  width="65px"
                                  height="65px"
                                  loading="lazy"
                                />
                              ) : (
                                <Avatar
                                  alt="avt"
                                  src={""}
                                  sx={{ width: 65, height: 65 }}
                                  variant="square"
                                />
                                // <img
                                //   src={`${LocalImages.AvatarIcon}`}
                                //   alt="clock img not found"
                                //   width="65px"
                                //   height="65px"
                                //   loading="lazy"
                                // />
                              )}
                            </Box>
                            <Box className={classes.rating}>
                              {" "}
                              <PreciseRating
                                nameProduct={
                                  item?.isExternal === true
                                    ? item?.team_up_company_detail[0]
                                      ?.companyDetail?.businessName
                                    : item?.job_detail?.clientDetail
                                      ?.businessName
                                }
                                address={
                                  item?.isExternal === true
                                    ? item?.team_up_company_detail[0]
                                      ?.companyDetail?.location?.address
                                    : `${item?.job_detail?.clientDetail?.location?.address}`
                                }
                              />
                            </Box>
                            <Box className={classes.lastDescription}>
                              <Typography
                                variant="h6"
                                color="text.secondary"
                                sx={{ marginTop: "15px" }}
                              >
                                {item?.job_detail?.clientDetail?.businessTagline
                                  ? `${item?.job_detail?.clientDetail?.businessTagline}`
                                  : `No business tagline added`}
                              </Typography>
                            </Box>
                            {CommentsData?.length > 0 ? (
                              <Box>
                                <Box
                                  onClick={() => {
                                    if (CommonFunction.isElementInViewport("comment_button")) {
                                      CommonFunction.scrollToElement("job_details", 100)
                                    }
                                    else {
                                      CommonFunction.scrollToElement("comment_button")
                                    }
                                  }}
                                  sx={{
                                    position: "fixed",
                                    marginLeft: "190px",
                                    marginTop: "20px",
                                    cursor: "pointer",
                                    bottom: "20px",
                                    right: "200px"
                                  }}
                                >
                                  <img
                                    src={LocalImages.ellipse}
                                    alt="ellipse"
                                    height="46px"
                                  />
                                  <img
                                    style={{
                                      position: "absolute",
                                      right: "15px",
                                      top: "18px",
                                      height: "10px",
                                      transform: `rotate(${inView ? 0 : 180}deg)`,
                                      transition: "all 0.2s ease-in-out"
                                    }}
                                    src={LocalImages.blueup}
                                    alt="ellipse"
                                  />
                                </Box>
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </PaperBody>
                  </Box>
                )}
              </Box>
            );
          })}
      </ThemeProvider>
      <ModalBox
        isOpen={modalOpen1}
        handleClose={() => setModalOpen1(false)}
        modalStyle={"deletemodalStyle"}
        children={
          <AssignModal.AssignEmployee
            data={jobData}
            handleClose={() => setModalOpen1(false)}
            modalContainerStyle={classes.deletemodalStyle}
          ></AssignModal.AssignEmployee>
        }
      />
      <ModalBox
        isOpen={modalOpen2}
        handleClose={() => setModalOpen2(false)}
        modalStyle={"deletemodalStyle"}
        children={
          <AssignModalMS.AssignEmployeeMS
            data={jobData}
            handleClose={() => setModalOpen2(false)}
            modalContainerStyle={classes.deletemodalStyle}
          ></AssignModalMS.AssignEmployeeMS>
        }
      />
      <ModalBox
        isOpen={modalOpen4}
        handleClose={() => setModalOpen4(false)}
        modalStyle={"deletemodalStyle"}
        children={
          <ExternalTeamModal.AssignEmployee
            data={jobData}
            handleClose={() => setModalOpen4(false)}
            modalContainerStyle={classes.deletemodalStyle}
          ></ExternalTeamModal.AssignEmployee>
        }
      />
      <ModalBox
        isOpen={modalOpen5}
        handleClose={() => setModalOpen5(false)}
        modalStyle={"deletemodalStyle"}
        children={
          <AssignModalMSExternal.AssignEmployeeMS
            data={jobData}
            handleClose={() => setModalOpen5(false)}
            modalContainerStyle={classes.deletemodalStyle}
          ></AssignModalMSExternal.AssignEmployeeMS>
        }
      />
      <ModalBox
        isOpen={modalOpen3}
        handleClose={() => setModalOpen3(false)}
        modalStyle={"deletemodalStyle"}
        children={
          <AssignModalMSR.AssignEmployeeMSR
            data={jobData}
            handleClose={() => setModalOpen3(false)}
            modalContainerStyle={classes.deletemodalStyle}
          ></AssignModalMSR.AssignEmployeeMSR>
        }
      />

      <BuildQuoteModal
        open={openSuccess}
        handleClose={handleCloseSuccess}
        heading="BUILD A QUOTE"
        subHeading="How would you like to get started? "
        id={id}
        setSpOwnModalOpen={setSpOwnModalOpen}
      />
      <SpOwnModal open={spOwnModalOpen} setOpen={setSpOwnModalOpen} />
      <EnquireModal ModalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
};

export default SpBuildsQuotes;
