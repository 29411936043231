import {
  Box,
  Button,
  CardActionArea,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Link,
  MobileStepper,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetStartedTooltipRemindMeLater,
  hideGetStartedTooltipPermanent,
} from "../../../../reducer/globalReducer";
import Utils from "../../../../Utils";

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 6,
  backgroundColor: "#097390",
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#9EDBEB",
  },
}));

export const GetStartedTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  )
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#333333",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#333333",
    border: "none",
    borderRadius: 0,
    padding: 0,
  },
}));

const TooltipCard = ({
  setStep,
  maxSteps,
  steps,
  label,
  description,
  value,
  setValue,
}: any) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { tutorialStep } = useSelector((state: any) => state?.globalReducer)

  const stepChange = (step: number) => {
    dispatch({
      type: Utils.ActionName.TUTORIAL_STEP,
      payload: step
    })
  }

  const calculateProgress = () => {
    return steps / maxSteps * 100
  }

  const handleNext = () => {
    if (setValue) {
      setValue((prev: any) => prev + 25);
    }
    stepChange(tutorialStep + 1)
    // setStep((prevActiveStep: any) => prevActiveStep + 1);
  };
  const handleBack = () => {
    if (setValue) {
      setValue((prev: any) => prev - 25);
    }
    stepChange(tutorialStep - 1)
  };

  const onClose = () => {
    console.log("onClose called");
    dispatch(hideGetStartedTooltipPermanent());
    stepChange(0)
  };

  const RemindMeLaterHandler = () => {
    console.log("remind me later called");
    dispatch(GetStartedTooltipRemindMeLater());
    stepChange(0)
  };

  return (
    <Box
      sx={{
        maxWidth: 250,
        margin: 0,
        padding: 0,
        backgroundColor: "#333333",
      }}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <Typography
            gutterBottom
            sx={{ color: "white", fontSize: "15px", fontWeight: "600" }}
            variant="h6"
            component="div"
          >
            {label}
          </Typography>
          <IconButton
            sx={{ position: "absolute", top: -10, right: -5 }}
            onClick={onClose}
          >
            <CloseIcon sx={{ color: "white", fontSize: "1.2rem" }} />
          </IconButton>
        </div>
        <Typography
          variant="body2"
          color="white"
          sx={{
            color: "white",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "21px",
          }}
        >
          {description}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <BorderLinearProgress
            sx={{ flex: 1 }}
            variant="determinate"
            value={calculateProgress()}
          />
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={steps - 1}
            sx={{
              backgroundColor: "#333333",
              color: "#fff",
            }}
            nextButton={
              <IconButton
                sx={{ width: "8px", color: "#fff" }}
                onClick={handleNext}
                disabled={steps + 1 > maxSteps}
              >
                {theme.direction === "rtl" ? (
                  <ArrowBackIosIcon sx={{ fontSize: "10px" }} />
                ) : (
                  <ArrowForwardIosIcon sx={{ fontSize: "10px" }} />
                )}
              </IconButton>
            }
            backButton={
              <IconButton
                sx={{ width: "5px", color: "#fff" }}
                onClick={handleBack}
                disabled={steps === 1}
              >
                {theme.direction === "rtl" ? (
                  <ArrowForwardIosIcon sx={{ fontSize: "10px" }} />
                ) : (
                  <ArrowBackIosIcon sx={{ fontSize: "10px" }} />
                )}
              </IconButton>
            }
          />
        </Stack>
      </CardContent>
      <Typography
        sx={{
          backgroundColor: "#4F4F4F",
          marginTop: "10px",
          padding: "20px",
          cursor: "pointer",
        }}
        align="center"
        onClick={RemindMeLaterHandler}
      >
        <Link color="#fff">Remind me later</Link>
      </Typography>
    </Box>
  );
};

export default TooltipCard;
