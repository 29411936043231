import { ImagesearchRollerOutlined } from "@mui/icons-material";
import {
  globalLoaderEnd,
  globalLoaderStart,
  ImageUploadLoaderEnd,
} from "../../../reducer/globalReducer";
import Utils from "../../../Utils";
import { uploadFile } from "../../../Utils/AwsFileUpload";

export const getExternalTradeDetails = (jobId: any, companyJobId: any) => {
  return (dispatch: any) => {
    const queryString = `?companyId=${localStorage.getItem(
      "user_id"
    )}&jobId=${jobId}&companyJobId=${companyJobId}`;

    dispatch(globalLoaderStart());
    Utils.API.getApiCall(
      Utils.endpoint.externalTradeDetails,
      queryString,
      (responseData: any) => {
        const { data } = responseData;

        if (data.statusCode === 200) {
          dispatch({
            type: Utils.ActionName.EXTERNAL_TRADE_DETAILS,
            payload: {
              externalTradeDetails: data.data,
            },
          });
        } else {
          Utils.showAlert(2, data?.message);
        }
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        let { data } = error;
        // Utils.showAlert(2, data?.message);
        window.location.reload();
        dispatch(globalLoaderEnd());
      }
    );
  };
};

export const companyJobDetails = (id: string) => (dispatch: Function) => {
  if (!navigator.onLine) {
    Utils.showAlert(2, "Please check your internet connection!");
    return;
  }
  dispatch(globalLoaderStart());

  const companyId = localStorage.getItem("user_id");

  let params = `?companyId=${companyId}&reqJobId=${id}`;

  Utils.API.getApiCall(
    Utils.endpoint.jobDetails,
    params,
    (response: any) => {
      const { data } = response;
      localStorage.setItem("jobId", data?.data[0]?.jobId);
      dispatch({
        type: Utils.ActionName.JOB_DETAILS,
        payload: {
          JobDetailsData: data?.data,
          ...data?.data[0],
        },
      });
      dispatch(globalLoaderEnd());
    },
    (error: any) => {
      let { data } = error;
      Utils.showAlert(2, data.message);
      dispatch(globalLoaderEnd());
    }
  );
};

export const getComment =
  (jobId: any, reqJobId: string, commentType?: string) =>
  (dispatch: Function) => {
    if (!navigator.onLine) {
      Utils.showAlert(2, "Please check your internet connection!");
      return;
    }
    dispatch(globalLoaderStart());

    let params = `?${
      reqJobId !== "" ? `reqJobId=${reqJobId}&&` : ""
    }jobId=${jobId}&commentType=${commentType}&limit=1000`;
    // let params = `?reqJobId=${id}&jobId=${jobId}&limit=1000&commentType=${commentType}`;

    Utils.API.getApiCall(
      Utils.endpoint.getComments,
      params,
      (response: any) => {
        const { data } = response;
        dispatch({
          type: Utils.ActionName.GET_COMMENTS,
          payload: {
            CommentsData: data?.data || [],
          },
        });
        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
      }
    );
  };

export const addComments = (
  message: string,
  jobId: string,
  reqJobId: string,
  commentType?: string
) => {
  return (dispatch: any) => {
    const dataToSend = {
      comment: message,
      jobId,
      ...(reqJobId !== "" && { reqJobId }),
      // reqJobId,
      type: 1,
      commentType: commentType,
      // image:[""]
    };
    Utils.API.postApiCall(
      Utils.endpoint.postComments,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 200) {
          // window.location.reload();
          dispatch(getComment(jobId, reqJobId, commentType));
          Utils.showAlert(1, data.message);
        } else {
          Utils.showAlert(1, data.message);
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
};

export const addCommentsImages =
  (
    images: any,
    message: string,
    jobId: string,
    reqJobId: string,
    commentType?: string
  ) =>
  async (dispatch: any) => {
    let imagesUrl = [];
    if (images.length > 0) {
      for (let el of images) {
        try {
          const imageData: any = await uploadFile(el.file);
          imagesUrl.push(imageData.Location);
        } catch (error) {
          Utils.showAlert(2, "Erroe occured while uploading an image");
          throw new Error("Error");
        }
      }
    }
    const dataToSend = {
      comment: message,
      jobId,
      ...(reqJobId !== "" && { reqJobId }),
      // reqJobId,
      type: 3,
      image: imagesUrl,
      commentType: commentType,
    };
    Utils.API.postApiCall(
      Utils.endpoint.postComments,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 200) {
          // window.location.reload();
          dispatch(getComment(jobId, reqJobId, commentType));
          Utils.showAlert(1, data.message);
        } else {
          Utils.showAlert(1, data.message);
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };

export const commentImages =
  (images: any, jobId: string, reqJobId: string, commentType?: string) =>
  async (dispatch: any) => {
    let imagesUrl = [];
    if (images.length > 0) {
      for (let el of images) {
        try {
          const imageData: any = await uploadFile(el.file);
          imagesUrl.push(imageData.Location);
        } catch (error) {
          Utils.showAlert(2, "Erroe occured while uploading an image");
          throw new Error("Error");
        }
      }
    }
    const dataToSend = {
      jobId,
      // reqJobId,
      ...(reqJobId !== "" && { reqJobId }),
      type: 2,
      image: imagesUrl,
      commentType: commentType,
    };
    Utils.API.postApiCall(
      Utils.endpoint.postComments,
      dataToSend,
      (responseData: any) => {
        const { data } = responseData;
        if (data.statusCode === 200) {
          // window.location.reload();
          dispatch(getComment(jobId, reqJobId, commentType));
          Utils.showAlert(1, data.message);
        } else {
          Utils.showAlert(1, data.message);
        }
      },
      (error: any) => {
        let { data } = error;
        Utils.showAlert(2, data.message);
      }
    );
  };
