class GlobalLoaderModal {
  isLoading = false;
}
class LoadingModel {
  isLoading: boolean = false;
  mainLoading: boolean = false;
}
class LoginModel {
  email: string = "";
  password: string = "";
}
class ForgotPasswordModel {
  email: string = "";
}
class ResetPasswordModel {
  token: string = "";
  newPassword: string = "";
  deviceId: string = "";
}

class SignupModel {
  name: string = "";
  email: string = "";
  password: string = "";
}

class ProfileSelectorModel {
  userType: string = "";
  businessName: any;
  abnNumber: any;
  location: any;
  address: any;
  skills: any;
  industryExp: any;
}

class DropDownListsModel {
  skills: [] = [];
  industrys: [] = [];
  roles: [] = [];
}

class EmployeeDropdownListModel {
  employees: [] = [];
}

class EmployeeTeamPermissionModel {
  emails: [] = [];
}

class InviteModel {
  emails: [] = [];
}

class AssignJobModel {
  userId: [] = [];
}

class AdminTeamPermissionModel {
  emails: [] = [];
}

class AdminTeamMembersDataModel {
  AdminteamMembersData: any = [];
  memberData: any = {};
  sortBy: string = "";
  sortOrder: number = 1;
  pageNo: number = 1;
  limit: number = 10000;
  userType: string = "";
  search2: string = "";
  searchResult: boolean = false;
}

class FinancialDataModel {
  FinancialData: any = [];
}

class TeamMembersDataModel {
  teamMembersData: any = [];
  memberData: any = {};
  sortBy: string = "";
  sortOrder: number = 1;
  pageNo: number = 1;
  limit: number = 10000;
  userType: string = "";
  search: string = "";
  adminSearchResult: boolean = false;
}

class EditTeamPermissionModel {
  teamIds: { [key: string]: any } = {
    _id: "string",
    updatedRole: "",
    email: "string",
  };
}

class JobDetailsModel {
  JobDetailsData: any = [];
  itemCosting: any = "";
  date: any = "";
  quoteValidDate: any;
  title: any = "";
  summary: any = "";
  draft: any = false;
  notes: any = "";
  comments: any = "";
  _id: any = "";
  quoteNumber: any = "";
  downloadUrl: any = "";
}

class CommentsModel {
  CommentsData: any = [];
}

class SelectTemplateModel {
  data: any = [];
  primoFoods: string = "";
  date: string = "";
  validUntil: string = "";
  quoteNumber: string = "";
  title: string = "";
  summaryofProject: string = "";
  dragData = [
    {
      description: "",
      quantity: "",
      unitPrice: "",
      discount: "",
      gst: "",
      amountAud: "",
    },
  ];
  draft: any = "";
  notes: any = "";
  id: any = "";
  quoteSelection: string = "";
  summary: string = "";
  isDraft: boolean = false;
  comments: string = "";
}

class ScratchTemplateModel {
  draggableForms: { [key: string]: any }[] = [];
  errorsForms: boolean[] = [];
  clientErrors: boolean = false;
  clientDetails: { [key: string]: any } | null = null;
}

class serviceProviderModel {
  serviceProviderList: any = [];
  filterServiceProvider: any = [];
  filterRequestType: any = [];
}

class scheduleBookingModel {
  scheduleBooking: any = [];
  pageNo: number = 1;
  limit: number = 3;
  filterRequestType: any = [];
}

class savedCompanyListingModel {
  savedCompanyList: any = [];
}

class NotificationListingModel {
  notificationListData: any = [];
}

class ProfileDetailsModel {
  profileDetailsData: any = [];
}

// Written by SArita

class RatesDetailsModel {
  ratesDetailsData: any = [];
}

class ServiceProviderSearchModel {
  serviceProviderSearch: any = [];
}
class CompanyDetailsModel {
  companyDetailsData: any = [];
}
class JobDetailsModel1 {
  jobDetailsData: any = [];
  otherJobDetailsData: any = [];
  tabValue: number = 2;
  bookedDetailsData: any = [];
  isEdit: boolean = false;
  customerData: any = [];
  files: any = []; //........
  jobId: any = "";
}

class ExternalTradeDetailsModel {
  externalTradeDetails: any = [];
}

class GlobalModal {
  tutorialStep: number = 0
}

class ReducersModel {
  globalLoaderReducer: GlobalLoaderModal = new GlobalLoaderModal();
  tutorialStep: GlobalModal = new GlobalModal();
  logInReducer: LoginModel = new LoginModel();
  forgotPasswordReducer: ForgotPasswordModel = new ForgotPasswordModel();
  resetPasswordReducer: ResetPasswordModel = new ResetPasswordModel();
  signUpReducer: SignupModel = new SignupModel();
  profileSelectorReducer: ProfileSelectorModel = new ProfileSelectorModel();
  dropDownReducer: DropDownListsModel = new DropDownListsModel();
  employeeDropdown: EmployeeDropdownListModel = new EmployeeDropdownListModel();
  commentsModelReducer: CommentsModel = new CommentsModel();
  employeeTeamPermissionReducer: EmployeeTeamPermissionModel =
    new EmployeeTeamPermissionModel();
  inviteReducer: InviteModel = new InviteModel();
  assignJobReducer: AssignJobModel = new AssignJobModel();
  adminTeamPermissionReducer: AdminTeamPermissionModel =
    new AdminTeamPermissionModel();
  teamMembersDataReducer: TeamMembersDataModel = new TeamMembersDataModel();
  financialDataModelReducer: FinancialDataModel = new FinancialDataModel();
  AdminteamMembersDataReducer: AdminTeamMembersDataModel =
    new AdminTeamMembersDataModel();
  editTeamPermissionReducer: EditTeamPermissionModel =
    new EditTeamPermissionModel();
  jobDetailsModelReducer: JobDetailsModel = new JobDetailsModel();
  selectTemplateModelReducer: SelectTemplateModel = new SelectTemplateModel();
  scratchTemplateReducer: ScratchTemplateModel = new ScratchTemplateModel();
  serviceProviderReducer: serviceProviderModel = new serviceProviderModel();
  scheduleBookingReducer: scheduleBookingModel = new scheduleBookingModel();
  savedCompanyListingReducer: savedCompanyListingModel =
    new savedCompanyListingModel();
  notificationListingReducer: NotificationListingModel =
    new NotificationListingModel();
  profiledetailsdataReducer: ProfileDetailsModel = new ProfileDetailsModel();
  ratesDetailsReducer: RatesDetailsModel = new RatesDetailsModel();
  serviceProviderSearchReducer: ServiceProviderSearchModel =
    new ServiceProviderSearchModel();
  jobDetailsReducer: JobDetailsModel1 = new JobDetailsModel1();
  externalTradeDetailsReducer: ExternalTradeDetailsModel = new ExternalTradeDetailsModel();
  companyDetailsReducer: CompanyDetailsModel = new CompanyDetailsModel();
}

export {
  ProfileDetailsModel,
  ReducersModel,
  GlobalLoaderModal,
  LoadingModel,
  LoginModel,
  ForgotPasswordModel,
  ResetPasswordModel,
  SignupModel,
  ProfileSelectorModel,
  DropDownListsModel,
  AssignJobModel,
  EmployeeDropdownListModel,
  EmployeeTeamPermissionModel,
  InviteModel,
  CommentsModel,
  AdminTeamPermissionModel,
  TeamMembersDataModel,
  FinancialDataModel,
  AdminTeamMembersDataModel,
  EditTeamPermissionModel,
  SelectTemplateModel,
  JobDetailsModel,
  ScratchTemplateModel,
  serviceProviderModel,
  scheduleBookingModel,
  savedCompanyListingModel,
  NotificationListingModel,
  RatesDetailsModel,
  ServiceProviderSearchModel,
  ExternalTradeDetailsModel,
  CompanyDetailsModel,
  GlobalModal
};
