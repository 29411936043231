import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    titleWrapper: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "-1.5rem",
      "& > :not(style)": {
        width: "76vw",
        marginBottom: 10,
      },
    },
    topSpace: {
      marginTop: "24px",
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
    },
    titleChildren: {
      display: "flex",
      justifyContent: "space-between",
      gap: 35,
    },
    root: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "0px",
      "& > :not(style)": {
        width: "76vw",
        minHeight: "80vh",
        border: "1px solid var(--modalBoxShadowColor)",
        borderRadius: "unset",
      },
    },
    rootHeightAuto: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "0px",
      "& > :not(style)": {
        width: "76vw",
        minHeight: "auto",
        border: "1px solid var(--modalBoxShadowColor)",
        borderRadius: "unset",
      },
    },
    body: {
      minHeight: "70vh",
    },
    heightAuto: {
      minHeight: "auto",
    },
    svgIcons: {
      display: "flex",
      flexWrap: "wrap",
      transform: "scale(0.9)",
    },
    svgIconsList: {
      display: "flex",
      flexWrap: "wrap",
      transform: "scale(0.3)",
      minWidth: "0px",
      "& .css-1555fzs-MuiListItemIcon-root": {
        minWidth: "0px",
      },
    },
    leftdescription: {
      flexGrow: 2,
      flexShrink: 1,
      backgroundColor: "white",
      flexWrap: "wrap",
      flexBasis: "75%",
      minHeight: "80vh",
    },
    rightdescription: {
      flexDirection: "row",
      flexGrow: 1,
      flexShrink: 0,
      // border:"1px solid black",
      height: "100% !important",
      flexWrap: "wrap",
      alignItems: "center",
      textAlign: "center",
      flexBasis: "25%",
    },
    clockRowText: {
      display: "flex",
      flexWrap: "wrap",
      gap: 20,
    },
    fileUpload: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      marginTop: 4,
    },
    boxContainerFile: {
      border: "1px solid grey",
      display: "flex",
      minHeight: 60,
      minWidth: 130,
      justifyContent: "space-between",
      flexWrap: "wrap",
      marginTop: 2,
      marginBottom: 10,
      marginRight: 20,
    },
    boxInnerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 2,
    },
    messageMilk: {
      display: "flex",
      flexWrap: "wrap",
      border: "1px solid grey",
      height: "48px",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
      textOverflow: "ellipsis [..]",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    deletemodalStyle: {
      position: "absolute",
      textAlign: "center",
      width: 455,
      height: 220,
      top: "50%",
      left: "60%",
      transform: "translate(-50%,-50%)",
      backgroundColor: "white",
      // [theme.breakpoints.down(600)]: {
      //   width: 327,
      //   height: 244,
      //   top: "50%",
      //   left: "50%",
      // },
    },
    rating: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      marginTop: "16px",
    },
    lastDescription: {},
  })
);

const useStylesBreakPoints = makeStyles(() =>
  createStyles({
    titleWrapper: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "-1.5rem",
      "& > :not(style)": {
        width: "76vw",
        marginBottom: 6,
      },
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
    },
    titleChildren: {
      display: "none",
    },
    root: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      "& > :not(style)": {
        width: "76vw",
        minHeight: "80vh",
        border: "1px solid var(--modalBoxShadowColor)",
        borderRadius: "unset",
      },
    },
    body: {
      minHeight: "70vh",
    },
    svgIcons: {
      display: "flex",
      flexWrap: "wrap",
      transform: "scale(0.9)",
    },
    svgIconsList: {
      display: "flex",
      flexWrap: "wrap",
      transform: "scale(0.3)",
      minWidth: "0px",
      "& .css-1555fzs-MuiListItemIcon-root": {
        minWidth: "0px",
      },
    },
    leftdescription: {
      flexShrink: 1,
      backgroundColor: "#E5E5E5",
      flexWrap: "wrap",
      flexBasis: "105%",
    },
    rightdescription: {
      flexDirection: "row",
      flexGrow: 1,
      flexShrink: 0,
      flexWrap: "wrap",
      alignItems: "center",
      textAlign: "center",
      flexBasis: "25%",
    },
    clockRowText: {
      display: "flex",
      flexWrap: "wrap",
      gap: 20,
    },
    fileUpload: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      marginTop: 4,
    },
    boxContainerFile: {
      border: "1px solid grey",
      display: "flex",
      minHeight: 60,
      minWidth: 130,
      justifyContent: "center",
      flexWrap: "wrap",
      marginTop: 2,
      marginBottom: 10,
      marginRight: 20,
    },
    boxInnerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 2,
    },
    buttonAssign: {
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: "var(--primaryBlueColor)",
      flexBasis: "25%",
      height: "60px",
      "&.MuiButton-root": {
        borderRadius: "unset",
      },
    },
    messageMilk: {
      display: "flex",
      flexWrap: "wrap",
      border: "1px solid grey",
      height: "61px",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
    },
    ratingBox: {
      marginTop: "12px",
      display: "flex",
      "@media (max-width: 300px)": {
        flexWrap: " wrap",
      },
    },
    rating: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      marginTop: "16px",
    },
    lastDescription: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export { useStyles, useStylesBreakPoints };
