import moment from 'moment';
import { memo, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, Box, Grid, FormLabel, TextField } from '@mui/material';
import ButtonContained from '../../../components/button/buttonContained';
import ButtonOutlined from '../../../components/button/buttonOutlined';
import { FinalSubmitSelectTemplate } from '../selectTemplate/action';
import ModalWrapper from '../../../components/modal/modalWrapper';
import LocalImages from '../../../Utils/localImages';
import { ReducersModel } from '../../../model';
import { theme } from '../../../theme/theme';
import { ThemeProvider } from '@mui/styles';
import { useStyles } from './spPreviewCss';
import Utils from '../../../Utils';

type ModalPreviewPDFProps = {
  open: boolean;
  handleClose: () => void;
  heading: string;
};
interface LocationState {
  pathname: string;
  id: string;
  quoteSelection: string;
}

function ModalPreviewPDF({ open, handleClose, heading }: ModalPreviewPDFProps) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation<LocationState>();
  const [comments, setcomments] = useState('');

  const { data } = useSelector(
    (state: ReducersModel) => state.selectTemplateModelReducer
  );

  const { JobDetailsData } = useSelector(
    (state: ReducersModel) => state.jobDetailsModelReducer
  );

  const {
    quoteDate,
    quoteValidDate,
    summary,
    title,
    quoteNumber,
    itemCosting,
  } = data;

  const computeSubTotal = () => {
    let number: number = 0;
    itemCosting?.forEach((data: any) => {
      const { amountAud } = data;
      number = number + Number(amountAud);
    });
    return number;
  };

  const handleCancel = () => {
    history.push({
      pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE,
      state: {
        id: location.state.id,
        quoteSelection: location.state.quoteSelection,
      },
    });
  };

  const handleEditClick = () => {
    history.push({
      pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE,
      state: {
        id: location.state.id,
        quoteSelection: location.state.quoteSelection,
      },
    });
  };
  const handleSubmit = (value: any) => {
    const newLocation = {
      ...value.location,
      coordinates: value.location.coordinates || [] // Ensure it's an array
  };

  const newAllValues = {
      ...value,
      isDraft: true,
      location: newLocation // Update location with new coordinates property
  };

  dispatch(
      FinalSubmitSelectTemplate(
          newAllValues,
          location.state.id,
          location.state.quoteSelection,
      )
  );

    history.push(Utils.Pathname.NAVBAR.AsCompany.home);
  };

  const handleChangecommentss = (e: any) => {
    setcomments(e.target.value);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <ModalWrapper
          open={open}
          handleClose={handleClose}
          sx={{
            borderRadius: (theme) => theme.spacing(0),
            maxHeight: '78vh',
            overflowY: 'scroll',
            '@media (min-width: 800px)': {
              width: { md: 800 },
            },
          }}
        >
          <Box className={classes.modalWrapperChild}>
            <Typography
              sx={{
                color: 'var(--modalHeadingColor)',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: 500,
                display: 'flex',
                marginBottom: 1,
                marginTop: 1,
              }}
            >
              {heading}
            </Typography>
            <Box onClick={handleClose} className={classes.handleCloseButton}>
              <ClearIcon />
            </Box>
            <Box></Box>
            <Box className={classes.gridMuiroot}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>
                    <Typography
                      sx={{
                        color: 'var(--clientColor)',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 400,
                        display: 'flex',
                        marginBottom: 1,
                      }}
                    >
                      Client
                    </Typography>
                  </FormLabel>
                  <TextField
                    name="client"
                    size="small"
                    fullWidth
                    value={
                      JobDetailsData.length > 0 &&
                      JobDetailsData.map((item: any) => {
                        return item?.job_detail?.clientDetail?.businessName;
                      })
                    }
                    sx={{
                      marginBottom: 2,
                      '@media (max-width: 420px)': {
                        width: '98%',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>
                    <Typography
                      sx={{
                        color: 'var(--clientColor)',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 400,
                        display: 'flex',
                        marginBottom: 1,
                      }}
                    >
                      Add comments
                    </Typography>
                  </FormLabel>
                  <TextField
                    fullWidth
                    rows={2}
                    maxRows={4}
                    onChange={(e: any) => handleChangecommentss(e)}
                    name="comments"
                    size="small"
                    sx={{
                      marginBottom: 2,
                      '@media (max-width: 420px)': {
                        width: '98%',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box sx={{ border: '1px solid #E0E0E0', marginBottom: 2 }}>
                    <Box className={classes.logoModal}>
                      <img
                        src={LocalImages.Logo}
                        alt="logo"
                        width="20%"
                        height="30%"
                      />
                      <Box
                        sx={{
                          position: 'absolute',
                          right: 50,
                          borderRadius: '50%',
                          border: '1px solid #E0E0E0',
                        }}
                        onClick={handleEditClick}
                      >
                        <EditIcon />
                      </Box>
                    </Box>
                    <Box className={classes.textBodyModal}>
                      <Box sx={{ display: 'flex', flexDirection:"column" ,flexWrap: 'wrap' }}>
                        <Typography>Client </Typography>
                        <Typography>{JobDetailsData?.map((item: any) => {
                        return item?.job_detail?.clientDetail?.businessName;
                      })}</Typography>
                      </Box>
                      <Box className={classes.clientBodyModal}>
                        <Box>
                          <Typography>Date </Typography>
                          <Typography>
                            {moment(quoteDate).format('DD/MM/YYYY')}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>Valid Until </Typography>
                          <Typography>
                            {moment(quoteValidDate).subtract(1,"day").format('DD/MM/YYYY')}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>Quote number </Typography>
                          <Typography>{quoteNumber}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className={classes.singleTextBodyModal}>
                      <Typography>Site Address</Typography>{' '}
                      <Typography>{`${JobDetailsData.map(
                        (item: any) =>
                          item?.job_detail?.clientDetail?.location?.address
                      )}`}</Typography>
                    </Box>
                    <Box className={classes.singleTextBodyModal}>
                      <Typography
                        sx={{ width: '80%', overflowWrap: 'break-word' }}
                      >
                       {`${JobDetailsData.map(
                        (item: any) =>
                          item?.job_detail?.clientDetail?.email
                      )}`}
                      </Typography>{' '}
                      {/* <Typography>Phone: 03 1234 5678</Typography> */}
                    </Box>
                    <Box className={classes.singleTextBodyModal}>
                      <Typography>{title}</Typography>
                    </Box>
                    <Box className={classes.singleTextBodyModal}>
                      <Typography>{summary}</Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>
                    <Typography
                      sx={{
                        color: 'var(--clientColor)',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 400,
                        display: 'flex',
                        marginBottom: 2,
                      }}
                    >
                      Total Cost (AUD)
                    </Typography>
                  </FormLabel>
                  <TextField
                    fullWidth
                    name="amountAud"
                    size="small"
                    value={`$`+(computeSubTotal() + (computeSubTotal() * 10) / 100).toFixed(2)}
                    sx={{ marginBottom: 2 }}
                  />
                </Grid>
              </Grid>
              <Box className={classes.modalButton}>
                <ButtonOutlined
                  icon={false}
                  editValue={false}
                  onClick={handleCancel}
                  sx={{
                    '@media (max-width: 600px)': {
                      width: '99%',
                      mb: 2,
                    },
                  }}
                >
                  Cancel
                </ButtonOutlined>

                <ButtonContained
                  icon={false}
                  onClick={() => handleSubmit(data)}
                  sx={{
                    '@media (max-width: 600px)': {
                      width: '99%',
                      mb: 2,
                    },
                  }}
                >
                  Submit
                </ButtonContained>
              </Box>
            </Box>
          </Box>
        </ModalWrapper>
      </ThemeProvider>
    </>
  );
}

export default memo(ModalPreviewPDF);
