import {
  Box,
  Button,
  TextField,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Divider,
} from "@mui/material";
import { useEffect } from "react";
import { Field, ErrorMessage } from "formik";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CommonFunctions from "../../../../Utils/commonFunctions";
import LocalImages from "../../../../Utils/localImages";
import { useStyles } from "../selectTemplateCss";
import TextError from "./Error/TextError";
import { DragIndicatorSharp } from "@mui/icons-material";

type dataValue = {
  description: string;
  quantity: string;
  unitPrice: string;
  discount: string;
  gst: string;
  amountAud: string;
};

function DragTable({ arrayHelper, value, data, setFieldValue }: any) {
  const classes = useStyles();

  const addTableRows = () => {
    arrayHelper.push(data[0]);
  };
  const deleteTableRows = (index: number) => {
    arrayHelper.remove(index);
  };

  const handleDragEnd = (e: any) => {
    if (!e.destination) return;
    let tempData = Array.from(value?.dragData);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setFieldValue("dragData", tempData);
  };
  const len = value.dragData.length;
  useEffect(() => {
    if (
      value?.dragData &&
      (value?.dragData[0]?.quantity !== "" ||
        value?.dragData[0]?.unitPrice !== "" ||
        value?.dragData[0]?.discount !== "")
    ) {
      let result: number;
      value?.dragData.map((data: dataValue, index: number) => {
        const { quantity, unitPrice, discount } = data;
        if (data.discount) {
          result = CommonFunctions.discountCal(quantity, unitPrice, discount);
        } else {
          result = CommonFunctions.withoutDiscountCal(quantity, unitPrice);
        }
        setFieldValue(`dragData.${index}.amountAud`, result);
        return result;
      });
    }
  }, [setFieldValue, value]);

  function onChange(e: any, field: any) {
    // console.log("Dis", e.target.value, field);
    if (e.target.value < 0) return;
    field.onChange(e);
  }

  const computeSubTotal = () => {
    let number: number = 0;
    value?.dragData.forEach((data: dataValue) => {
      const { amountAud } = data;
      number = number + Number(amountAud);
    });
    return number;
  };

  const getDecimalOrIntegerDiscount = (val: any) => {
    const strVal = "" + val;
    if (strVal.includes(".")) {
      console.log("Dis", Number(strVal.slice(0, 5)));
      return Number(strVal.slice(0, 5));
    } else if (strVal) {
      return parseInt(strVal.slice(0, 2));
    }
    return "";
    // return val;
  };

  const getDecimalOrIntegerPrice = (val: any) => {
    const strVal = "" + val;
    if (strVal.includes(".")) {
      return Number(strVal.slice(0, 8));
    } else if (strVal) {
      return parseInt(strVal.slice(0, 10));
    }
    return "";
    // return val;
  };
  console.log(len, "length");
  return (
    <>
      <TableContainer>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Table
            sx={{
              minWidth: 700,
              "&:last-child td, &:last-child th": {
                border: "1px solid var(--modalBoxShadowColor)",
              },
            }}
            className={classes.customTable}
          >
            <TableHead
              sx={{ bgcolor: "var(--white)", textTransform: "capitalize" }}
            >
              <TableRow sx={{ height: "60px", textTransform: "capitalize" }}>
                <TableCell align="center"></TableCell>
                <TableCell align="left" width="520px">
                  <Typography sx={{ textTransform: "capitalize" }}>
                    Description
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <Typography sx={{ textTransform: "capitalize" }}>
                    Quantity
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ textTransform: "capitalize" }}>
                    Unit Price
                  </Typography>
                </TableCell>
                {/* <TableCell align="center">
                  <Typography sx={{ textTransform: "capitalize" }}>
                    Dis (%)
                  </Typography>
                </TableCell> */}
                <TableCell align="center">
                  <Typography sx={{ textTransform: "capitalize" }}>
                    GST
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography sx={{ textTransform: "capitalize" }}>
                    Amount AUD
                  </Typography>
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <Droppable droppableId="droppable-1">
              {(provider) => (
                <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                  {value.dragData.length > 0 &&
                    value?.dragData.map((value: dataValue, index: number) => {
                      return (
                        <Draggable
                          key={index}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(provider) => (
                            <TableRow
                              sx={{ height: "70px" }}
                              key={index}
                              {...provider.draggableProps}
                              ref={provider.innerRef}
                            >
                              <TableCell {...provider.dragHandleProps}>
                                <DragIndicatorIcon />
                              </TableCell>
                              <TableCell align="center">
                                <Field name={`dragData.${index}.description`}>
                                  {({ field }: any) => {
                                    return (
                                      <>
                                        <TextField
                                          {...field}
                                          onChange={(e) => onChange(e, field)}
                                          fullWidth
                                          variant="standard"
                                          focused={false}
                                          value={value.description}
                                          InputProps={{
                                            disableUnderline: true,
                                            inputProps: {
                                              style: {
                                                marginLeft: "10px",
                                                fontSize: "0.875rem",
                                                fontWeight: 400,
                                                lineHeight: 1.059,
                                              },
                                            },
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`dragData.${index}.description`}
                                          component={TextError}
                                        />
                                      </>
                                    );
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell align="center">
                                <Field name={`dragData.${index}.quantity`}>
                                  {({ field }: any) => {
                                    return (
                                      <>
                                        <TextField
                                          {...field}
                                          type="number"
                                          fullWidth
                                          onChange={(e) => onChange(e, field)}
                                          variant="standard"
                                          focused={false}
                                          value={parseInt(value.quantity)}
                                          InputProps={{
                                            disableUnderline: true,
                                            inputProps: {
                                              style: {
                                                marginLeft: "10px",
                                                fontSize: "0.875rem",
                                                fontWeight: 400,
                                                lineHeight: 1.059,
                                                textAlign: 'center'
                                              },
                                            },
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`dragData.${index}.quantity`}
                                          component={TextError}
                                        />
                                      </>
                                    );
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell align="center">
                                <Field name={`dragData.${index}.unitPrice`}>
                                  {({ field }: any) => {
                                    return (
                                      <>
                                        <TextField
                                          {...field}
                                          type="number"
                                          onChange={(e) => onChange(e, field)}
                                          fullWidth
                                          variant="standard"
                                          focused={false}
                                          value={getDecimalOrIntegerPrice(
                                            value.unitPrice
                                          )}
                                          InputProps={{
                                            disableUnderline: true,
                                            inputProps: {
                                              style: {
                                                marginLeft: "10px",
                                                fontSize: "0.875rem",
                                                fontWeight: 400,
                                                lineHeight: 1.059,
                                                textAlign: 'center'
                                              },
                                            },
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`dragData.${index}.unitPrice`}
                                          component={TextError}
                                        />
                                      </>
                                    );
                                  }}
                                </Field>
                              </TableCell>

                              {/* <TableCell align="center">
                                <Field name={`dragData.${index}.discount`}>
                                  {({ field }: any) => {
                                    return (
                                      <>
                                        <TextField
                                          {...field}
                                          fullWidth
                                          type="number"
                                          onChange={(e) => onChange(e, field)}
                                          variant="standard"
                                          value={getDecimalOrIntegerDiscount(
                                            value.discount
                                          )}
                                          focused={false}
                                          // inputProps={{
                                          //   max: 99.99,
                                          //   min: 0.0,
                                          //   step: 0.1,
                                          // }}
                                          // onInput={(e: any) => {
                                          //   const dis = e.target.value;
                                          //   if (dis.includes(".")) {
                                          //     console.log("Dis/include");
                                          //     e.target.value = Math.max(
                                          //       0,
                                          //       parseFloat(dis)
                                          //     )
                                          //       .toString()
                                          //       .slice(0, 5);
                                          //   } else {
                                          //     console.log("Dis/!include");
                                          //     e.target.value = Math.max(
                                          //       0,
                                          //       parseInt(dis)
                                          //     )
                                          //       .toString()
                                          //       .slice(0, 2);
                                          //   }
                                          // }}
                                          InputProps={{
                                            disableUnderline: true,
                                            inputProps: {
                                              style: {
                                                marginLeft: "10px",
                                                fontSize: "0.875rem",
                                                fontWeight: 400,
                                                lineHeight: 1.059,
                                              },
                                            },
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`dragData.${index}.discount`}
                                          component={TextError}
                                        />
                                      </>
                                    );
                                  }}
                                </Field>
                              </TableCell> */}
                              <TableCell align="center">
                                <Field name={`dragData.${index}.gst`}>
                                  {({ field }: any) => {
                                    return (
                                      <>
                                        <TextField
                                          {...field}
                                          fullWidth
                                          value={"10%"}
                                          onChange={(e) => onChange(e, field)}
                                          variant="standard"
                                          focused={false}
                                          InputProps={{
                                            readOnly: true,
                                            disableUnderline: true,
                                            inputProps: {
                                              style: {
                                                marginLeft: "10px",
                                                fontSize: "0.875rem",
                                                fontWeight: 400,
                                                lineHeight: 1.059,
                                                textAlign: 'center'
                                              },
                                            },
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`dragData.${index}.gst`}
                                          component={TextError}
                                        />
                                      </>
                                    );
                                  }}
                                </Field>
                              </TableCell>
                              <TableCell align="center">
                                <Field name={`dragData.${index}.amountAud`}>
                                  {({ field }: any) => {
                                    return (
                                      <>
                                        <TextField
                                          {...field}
                                          value={
                                            field.value
                                              ? field.value.toFixed(2)
                                              : ""
                                          }
                                          fullWidth
                                          // onChange={(e) => onChange(e, field)}
                                          variant="standard"
                                          focused={false}
                                          InputProps={{
                                            disableUnderline: true,
                                            readOnly: true,
                                            inputProps: {
                                              style: {
                                                marginLeft: "10px",
                                                fontSize: "0.875rem",
                                                fontWeight: 400,
                                                lineHeight: 1.059,
                                                textAlign: 'center'
                                              },
                                            },
                                          }}
                                        />
                                        <ErrorMessage
                                          name={`dragData.${index}.amountAud`}
                                          component={TextError}
                                        />
                                      </>
                                    );
                                  }}
                                </Field>
                              </TableCell>

                              <TableCell>
                                {len !== 1 ? (
                                  <Button
                                    onClick={() => deleteTableRows(index)}
                                  >
                                    <img
                                      src={LocalImages.crossIcon}
                                      alt="no found"
                                      height="11.67px"
                                    />
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      );
                    })}
                  {provider.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </TableContainer>
      <Box className={classes.addButtonRow}>
        <Box sx={{ width: "60%" }}>
          <Button
            onClick={addTableRows}
            variant="outlined"
            sx={{ border: "1px solid #E0E0E0", borderRadius: "0px" }}
          >
            + Add a new line{" "}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
            // position: "relative",
            // left: "70%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10%",
              // width: "200px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "262px",
              }}
            >
              <Typography
                sx={{
                  color: "#333333",
                  fontSize: "14px",
                  width: "50%",
                  textAlign: "right",
                }}
              >
                Subtotal
              </Typography>
              <Typography
                sx={{
                  color: "#333333",
                  fontSize: "14px",
                  width: "50%",
                  textAlign: "right",
                }}
              >
                {computeSubTotal().toFixed(2)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "#333333",
                  fontSize: "14px",
                  width: "50%",
                  textAlign: "right",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                Total Gst 10%
              </Typography>
              <Typography
                sx={{
                  color: "#333333",
                  fontSize: "14px",
                  width: "50%",
                  textAlign: "right",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              >
                {((computeSubTotal() * 10) / 100).toFixed(2)}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{ height: "1px", backgroundColor: "#000", width: "262px" }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  fontSize: "18px",
                  textAlign: "right",
                }}
              >
                <Typography
                  sx={{
                    width: "50%",
                    fontSize: "24px !important",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    textAlign: "right",
                    paddingRight: "10px"
                  }}
                >
                  Total
                </Typography>
                <Typography
                  sx={{
                    width: "50%",
                    fontSize: "24px !important",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  {(computeSubTotal() + (computeSubTotal() * 10) / 100).toFixed(
                    2
                  )}
                </Typography>
              </Box>
              <Box
                sx={{ height: "1px", backgroundColor: "#000", width: "262px" }}
              />
              <Box
                sx={{
                  height: "1px",
                  backgroundColor: "#000",
                  width: "262px",
                  marginTop: "1%",
                }}
              />
            </Box>
          </Box>
        </Box>

      </Box>
    </>
  );
}
export default DragTable;
