import { Box, styled } from "@mui/material";
import { CostColProps } from "./prototypes";

export const CostContainer = styled(Box)(
  ({ _theme, isActive, cursor }: any) => ({
    marginTop: 24,
    background: "#fff",
    border: isActive ? "1px solid var(--buttonHover)" : "1px solid #e0e0e0",
    padding: "22px 32px 28px 24px",
    position: "relative",
    cursor,
  })
);

export const TextContainer = styled(Box)(
  ({ _theme, isActive, cursor }: any) => ({
    marginTop: 24,
    background: "#fff",
    border: isActive ? "1px solid var(--buttonHover)" : "1px solid #e0e0e0",
    padding: "22px 32px 28px 24px",
    position: "relative",
    cursor,
  })
);

export const TitleField = styled("input")(({ _theme }: any) => ({
  height: 35,
  width: "100%",
  fontSize: 16,
  outline: "none",
  paddingLeft: 12,
  background: "#fff",
  border: "1px solid #e0e0e0",
}));

export const CostHeadingRow = styled(Box)(({ _theme }: any) => ({
  fontSize: 14,
  marginTop: 14,
  display: "flex",
  justifyContent: "space-bewteen",
  border: "1px solid #e0e0e0",
}));

export const CostBodyRow = styled(Box)(({ _theme }: any) => ({
  fontSize: 14,
  display: "flex",
  justifyContent: "space-bewteen",
  borderLeft: "1px solid #e0e0e0",
  borderBottom: "1px solid #e0e0e0",
  borderRight: "1px solid #e0e0e0",
  color: "#828282",
}));

export const CostCol = styled(Box)<CostColProps>(
  ({ _theme, width, paddingLeft, $bdr, justifyContent }: any) => ({
    width,
    paddingLeft,
    paddingTop: "8px",
    paddingBottom: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent,
    borderRight: $bdr ? "1px solid #e0e0e0" : "none",
    color: "#828282",
    // borderBottom: $bdr ? "0.5px solid #e0e0e0" : "none",
  })
);

export const SVGDragContainer = styled(Box)(({ _theme }: any) => ({
  position: "absolute",
  right: 10,
  top: 10,
}));
