import { useHistory, useParams } from "react-router-dom";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { theme } from "../../theme/theme";
import Utils from "../../Utils/index";
import React, { useEffect } from "react";
import {
  BoxContainer,
  StyledIconButton,
} from "../../components/styledComponents";
import { scratchQuote } from "./actions";
import { makeScratchParameter } from "./helper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import QuoteWrapper from "./QuoteWrapper";
import DraggableWrapper from "./DraggableWrapper";
import FilledButton from "../../components/button/filledButton";
import { getIsDisabledValue } from "./DraggableWrapper/helper";
import { Link } from "@mui/material";
import { companyJobDetails } from "../sp/spBuildsQuotes/action";
import useRedux from "../../hooks/useRedux";
import { convertNestedObjectToOne } from "./helper";
import { useStyles } from "../sp/selectTemplate/selectTemplateCss";
import Title from "./Title";

const HeaderContainer = () => {
  const {
    dispatch,
    reducers: {
      jobDetailsModelReducer: { JobDetailsData },
    },
  } = useRedux();
  const history = useHistory();
  const classes = useStyles();
  const { reducers } = useRedux();
  const { draggableForms, errorsForms, clientErrors, clientDetails } =
    reducers.scratchTemplateReducer;

  const isDisabled: boolean = getIsDisabledValue(errorsForms, clientErrors);
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    dispatch(companyJobDetails(id));
    dispatch({
      type: Utils.ActionName.SCRATCH_TEMPLATE,
      payload: {
        draggableForms: [],
        errorsForms: [],
        clientErrors: false,
        clientDetails: null,
      },
    });
  }, [dispatch, id]);

  const handleClickPreview = () => {
    history.push(Utils.Pathname.BUILD_QUOTE_SCRATCH_PREVIEW + `/${id}`);
  };

  const objValues =
    Array.isArray(JobDetailsData) && JobDetailsData.length > 0
      ? convertNestedObjectToOne(JobDetailsData[0])
      : null;
  return (
    <Title>
      <Typography variant="h4" color="text.secondary">
        Build Quote
      </Typography>
      <Box className={classes.titleChildren}>
        {JobDetailsData[0]?.isDraft ? (
          <Box
            className={classes.titleIcons}
            sx={{
              width: "36px",
              height: "36px",
              display: "flex",
              minWidth: "36px",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Button
              onClick={handleClickPreview}
              sx={{
                width: "36px",
                height: "36px",
                minWidth: "36px",
                display: "flex",
                alignItems: "center",
                padding: "0",
              }}
            >
              <VisibilityIcon
                sx={{
                  color: "var(--primaryBlueColor)",
                  marginTop: "-6px",
                  position: "relative",
                  top: "4px",
                }}
              />
            </Button>
          </Box>
        ) : (
          <Box
            className={classes.titleIcons}
            sx={{
              width: "36px",
              height: "36px",
              minWidth: "36px",
              display: "flex",
              alignItems: "center",
              padding: "0",
            }}
          >
            <Button
              sx={{
                width: "36px",
                height: "36px",
                minWidth: "36px",
                display: "flex",
                alignItems: "center",
                padding: "0",
              }}
            >
              <VisibilityIcon
                sx={{
                  color: "var(--modalHeadingColor)",
                  marginTop: "-6px",
                  position: "relative",
                  top: "4px",
                }}
              />
            </Button>
          </Box>
        )}
        <Box
          className={classes.titleIcons}
          sx={{
            width: "36px",
            height: "36px",
            minWidth: "36px",
            display: "flex",
            alignItems: "center",
            padding: "0",
            margin: "0 20px",
          }}
        >
          {objValues?.downloadUrl ? (
            <Link
              href={objValues?.downloadUrl}
              underline="none"
              color="secondary.contrastText"
            >
              <Button
                sx={{
                  width: "36px",
                  height: "36px",
                  minWidth: "36px",
                  display: "flex",
                  alignItems: "center",
                  padding: "0",
                }}
              >
                <FileDownloadIcon
                  sx={{
                    color: "var(--primaryBlueColor)",
                    marginTop: "-6px",
                    position: "relative",
                    top: "4px",
                  }}
                />
              </Button>
            </Link>
          ) : (
            <Button
              sx={{
                width: "36px",
                height: "36px",
                minWidth: "36px",
                display: "flex",
                alignItems: "center",
                padding: "0",
              }}
            >
              <FileDownloadIcon
                sx={{
                  color: "var(--modalHeadingColor)",
                  marginTop: "-6px",
                  position: "relative",
                  top: "4px",
                }}
              />
            </Button>
          )}
        </Box>

        <FilledButton
          width={125}
          height={35}
          background={isDisabled ? "var(--primaryBlueColor)" : "#E0E0E0"}
          color={isDisabled ? "var(--white)" : "#000000"}
          border={"none"}
          disabled={!isDisabled}
          onClick={() => {
            dispatch(
              scratchQuote(
                makeScratchParameter(id, true, {
                  ...clientDetails,
                  draggableForms,
                  comments: "",
                })
              )
            );
          }}
        >
          {"Save as draft"}
        </FilledButton>
      </Box>
    </Title>
  );
};

const SpCreateScratch = () => {
  const { id } = useParams<{ id: string }>();
  const {
    dispatch,
    reducers: {
      jobDetailsModelReducer: { JobDetailsData },
    },
  } = useRedux();

  const costObj = { title: "COSTS", description: "String" };

  const objValues =
    Array.isArray(JobDetailsData) && JobDetailsData.length > 0
      ? convertNestedObjectToOne(JobDetailsData[0])
      : null;

  // console.log("NNNN", objValues);

  React.useEffect(() => {
    dispatch(companyJobDetails(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      {objValues && (
        <React.Fragment>
          <HeaderContainer />
          <QuoteWrapper initialObj={objValues} />
          <DraggableWrapper initialObj={objValues} />
        </React.Fragment>
      )}
    </ThemeProvider>
  );
};

export default SpCreateScratch;
