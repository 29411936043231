import { ReactNode, useEffect, useRef, useState } from "react"

export const useOnScreen = () => {
    const elementRef = useRef<any>(null);
    const [inView, setInView] = useState<boolean>(true);

    useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
        setInView(entry.isIntersecting);
    }, {threshold: 0.5});
    if(elementRef.current) {
        observer.observe(elementRef.current);
    }
    return () => {
        if(elementRef.current) {
            observer.unobserve(elementRef.current);
        }
    }
    }, [elementRef.current])
    return {
        elementRef,
        inView
    }
}
