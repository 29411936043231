const endPoint = {
  login: "/v1/company/login",
  forgotPassword: "/v1/company/forgot-password",
  profile: "/v1/company/profile",
  profileComplete: "/v1/company/profile-complete",
  resendLink: "/v1/company/resend-link",
  resetPassword: "/v1/company/reset-password",
  signUp: "/v1/company/signup",
  verifyAccount: "/v1/company/verify-account",
  logout: "/v1/company/logout",
  skills: "/v1/common/dropdown",
  industrys: "/v1/common/dropdown",
  verify: "/v1/company/verify-account",
  currentUser: "/v1/company/profile",
  editCurrentUser: "/v1/company/edit-profile",
  editBusinessProfile: "/v1/company/edit-business-profile",
  rateslist: "/v1/rates",
  rateDetails: "/v1/rate",
  inviteEmployee: "/v1/company/invite-employee",
  importCsv: "/v1/company/upload-csv-device",
  deleteEmployee: "/v1/company/delete-user-account",
  employeeList: "/v1/company/employee-list",
  inviteAdmin: "/v1/company/admin-invite",
  editPermission: "/v1/company/edit-permission",
  clientSearch: "/v1/common/client-search",
  dicountClientList: "/v1/discount/client-list",
  addClientToDiscountClientList: "/v1/discount/add-client",
  addDiscount: "/v1/discount",
  jobs: "/v1/company/jobs",
  deleteDiscountorClient: "/v1/discount/delete",
  jobDetails: "/v1/company/job-detail",
  buildQuotes: "/v1/company/build-quote",
  buildQuotesScratch: "/v1/company/build-quote/scratch",
  buildQuoteOwn: "/v1/company/build-quote/upload-pdf",
  tooltip: "/v1/common/tool-tip",
  emoloyeeListDashboard: "/v1/common/employee-list",
  getComments: "/v1/jobs/comment",
  postComments: "/v1/jobs/comment",
  assignJob: "/v1/company/assign-job",
  jobsCounter: "/v1/company/job-counter",
  uploadCertificate: "/v1/common/upload-single-certificate",
  verifyCertificate: "/v1/common/verify-certificate",
  book: "/v1/jobs/booking-job",
  assignJobMs: "/v1/company/ms-assign-job",
  scheduleBookings: "/v1/common/schedule-booking",
  guestCompanyListing: "/v1/common/guest-company-client-list",
  saveCompany: "/v1/common/save-company",
  companyListing: "/v1/common/company-client-list",
  savedCompanyListing: "/v1/common/saved-company-list",
  roles: "/v1/common/dropdown",
  bookingDelete: "/v1/jobs/booking-delete",
  financialOnboarding: "/v1/company/financial-onboard-client-list",
  notificationSetting: "/v1/company/notification-setting",
  settingInvite: "/v1/common/setting-invite",
  notificationList: "/v1/common/notification-list",
  readUnreadNotification: "/v1/common/notifications-data-update",
  downloadFinancial: "/v1/company/export-client-financial-onboarding-info",
  sendEnquiry: "/v1/common/send-enquiry-form",
  selectServiceProvider: "/v1/common/company-search",
  clientRate: "/v1/common/client-rate",
  projectSupport: "/v1/client/project-service-jobs",
  createEmergencyCallOut: "/v1/client/rapid-response-jobs",
  createMaintenanceServices: "/v1/client/maintenance-service-jobs",
  externalTradeJobs: "/v1/company/external-trades-jobs",
  externalTradeDetails: "/v1/company/external-trade-detail",
  externalTradesList: "/v1/company/external-trade-list",
  externalTradesAssign: "/v1/company/external-assign-job",
  externalTradeBooking: "/v1/jobs/external-trade-booking-job",
  externalBookingDelete: "/v1/jobs/external-booking-delete",
  contactUs: "/v1/company/contact-us",
  payment: "/v1/rate/payment",
  companyDetails: "/v1/common/company-details",
  uploadProfile:"/v1/common/media-upload"
};
export default endPoint;
