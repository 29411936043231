import { AxiosResponse } from "axios";
import moment from "moment";
import {
  globalLoaderEnd,
  globalLoaderStart,
} from "../../../reducer/globalReducer";
import Utils from "../../../Utils";
import { ifStringThenTrim } from "../../../Utils/commonFunctions";
import { INITIAL_FORM_FORMIK_VALUE } from "./index";

interface ResponseData {
  quoteSelection: string;
  quoteDate: string;
  quoteValidDate: string;
  quoteNumber: string;
  title: string;
  summary: string;
  isDraft: boolean;
  itemCosting: string;
  notes: string;
  companyId: string;
  reqJobId: string;
  comments: string;
}
interface AxiosData<T> {
  Data: T;
  Errors: unknown[];
  IsSuccess: boolean;
  message: string;
  statusCode: number;
}
export const SubmitSelectTemplate = (
  values: INITIAL_FORM_FORMIK_VALUE,
  refId: string,
  quoteSelection: string,
  history: any
) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      dispatch(globalLoaderStart());
    }

    const {
      date,
      validUntil,
      quoteNumber,
      title,
      summary,
      isDraft,
      dragData,
      notes,
      comments,
      location,
    } = values;
    dispatch(globalLoaderStart());
    const companyId = localStorage.getItem("user_id");

    const parametersValues = {
      quoteSelection: ifStringThenTrim(quoteSelection),
      quoteDate: moment(date).format("YYYY-MM-DD"),
      quoteValidDate: moment(validUntil).format("YYYY-MM-DD"),
      quoteNumber: ifStringThenTrim(quoteNumber),
      title: ifStringThenTrim(title),
      summary: ifStringThenTrim(summary),
      isDraft: ifStringThenTrim(isDraft),
      itemCosting: dragData,
      notes: ifStringThenTrim(notes),
      companyId: companyId,
      reqJobId: refId,
      comments: ifStringThenTrim(comments),
      location,
    };
    console.log(parametersValues, location, 'params-asd');
    let params = { ...parametersValues };
    Utils.API.putApiCall(
      Utils.endpoint.buildQuotes,
      params,
      (res: AxiosResponse<AxiosData<ResponseData>>) => {
        const { data } = res;
        dispatch({
          type: Utils.ActionName.SELECT_TEMPLATE_MODEL,
          payload: {
            ...data,
          },
        });
        if (history) {
          history.push({
            pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE_PREVIEW,
            state: {
              id: refId,
              quoteSelection: quoteSelection,
            },
          });
        }
        dispatch(globalLoaderEnd());
      },
      (error: AxiosResponse<AxiosData<ResponseData>>) => {
        let { data } = error;
        console.log(data, error, 'params-asd');
        // Utils.showAlert(2, data.message);
        dispatch(globalLoaderEnd());
        Utils.showAlert(2, data.message);
      }
    );
  };
};

export const FinalSubmitSelectTemplate = (
  values: any,
  refId: string,
  quoteSelection: string
) => {
  return (dispatch: Function) => {
    if (!navigator.onLine) {
      dispatch(globalLoaderStart());
    }

    const {
      quoteDate,
      quoteValidDate,
      quoteNumber,
      title,
      summary,
      isDraft,
      dragData,
      notes,
      comments,
      location,
    } = values;
    dispatch(globalLoaderStart());
    const companyId = localStorage.getItem("user_id");
    console.log(values,"Values in the CompanyId")
    const { type, ...newLocation } = location; // Destructure location object and exclude the 'type' key
    const parametersValues = {
      quoteSelection: quoteSelection,
      quoteDate: moment(quoteDate).format("YYYY-MM-DD"),
      quoteValidDate: moment(quoteValidDate).subtract(1, "day").format("YYYY-MM-DD"),
      quoteNumber: ifStringThenTrim(quoteNumber),
      title: ifStringThenTrim(title),
      summary: ifStringThenTrim(summary),
      isDraft: ifStringThenTrim(isDraft),
      itemCosting: dragData,
      notes: ifStringThenTrim(notes),
      companyId: companyId,
      reqJobId: refId,
      comments: ifStringThenTrim(comments),
      location: newLocation, // Use the newLocation object without the 'type' key
    };
    

    let params = { ...parametersValues };
    console.log(params, location, 'params-asd')
    Utils.API.putApiCall(
      Utils.endpoint.buildQuotes,
      params,
      (res: AxiosResponse<AxiosData<ResponseData>>) => {
        const { data } = res;

        if (data.statusCode === 200) {
          dispatch({
            type: Utils.ActionName.SELECT_TEMPLATE_MODEL,
            payload: {
              data: "",
              quoteSelection: "TEMPLATE",
              date: "",
              validUntil: "",
              quoteNumber: "",
              title: "",
              summary: "",
              dragData: [
                {
                  description: "",
                  quantity: "",
                  unitPrice: "",
                  discount: "",
                  gst: "10",
                  amountAud: "",
                },
              ],
              isDraft: false,
              notes: "",
              id: "",
              comments: "",
              location:{
                address:'',
                coordinates:''
              }
            },
          });
          Utils.showAlert(1, data.message);
        }

        dispatch(globalLoaderEnd());
      },
      (error: any) => {
        let { data } = error;
        console.log(data, error, 'params-asd');
        Utils.showAlert(2, 'Quote Submitted Successfully');
        dispatch(globalLoaderEnd());
      }
    );
  };
};

