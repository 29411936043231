import { Box, Paper } from "@mui/material";
import { useStyles } from ".././spBuildsQuotes/spBuildsQuotesCss";

const PaperBody = ({ children, isHeightAuto, topSpace }: any) => {
  const classes = useStyles();
  return (
    <div>
      <Box className={isHeightAuto ? classes.rootHeightAuto : classes.root}>
        {topSpace ? <Box
          className={`${isHeightAuto ? classes.heightAuto : classes.body}`}
        >
          {children}
        </Box> :
          <Paper elevation={0}>
            <Box
              className={`${isHeightAuto ? classes.heightAuto : classes.body}`}
            >
              {children}
            </Box>
          </Paper>
        }
      </Box>
    </div>
  );
};

export default PaperBody;
