import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useHistory, useLocation } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Box, Button, Grid, Typography, Link } from "@mui/material";
import DateTimePicker from "../../../components/DataTimePicker";
import TextfieldWrapper from "../../../components/Textfield";
import { companyJobDetails } from "../spBuildsQuotes/action";
import MobileResponsiveQuote from "./mobileResponsive";
import { SubmitSelectTemplate } from "./action";
import { useStyles } from "./selectTemplateCss";
import { ReducersModel } from "../../../model";
import { theme } from "../../../theme/theme";
import PaperBody from "../PaperBody";
import Schema from "../../../schema";
import DragTable from "./DragTable";
import Utils from "../../../Utils";
import Title from "../Title";
import Autocomplete from "react-google-autocomplete";
interface LocationState {
  pathname: string;
  id: string;
  quoteSelection: string;
  history: any;
}
export interface INITIAL_FORM_FORMIK_VALUE {
  quoteSelection: string;
  date: string;
  validUntil: string;
  quoteNumber: string;
  title: string;
  summary: string;
  dragData: {
    description: string;
    quantity: string;
    unitPrice: string;
    discount: string;
    gst: string;
    amountAud: string;
  }[];
  isDraft: boolean;
  notes: string;
  id: string;
  comments: string;
  location: {
    address: string;
    coordinates: string[];
  };
}

const INITIAL_FORM_STATE: INITIAL_FORM_FORMIK_VALUE = {
  quoteSelection: "",
  date: moment().format("YYYY-MM-DD"),
  validUntil: "",
  quoteNumber: "",
  title: "",
  summary: "",
  dragData: [
    {
      description: "",
      quantity: "",
      unitPrice: "",
      discount: "0",
      gst: "10",
      amountAud: "",
    },
  ],
  isDraft: false,
  notes: "Thank You !",
  id: "",
  comments: "",
  location: {
    // Initialize the location object
    address: "",
    coordinates: [],
  },
};

const SelectTemplate = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<LocationState>();
  const themeBreak = useTheme();
  const mobileView = useMediaQuery(themeBreak.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const [downloadIcon, setDownloadIcon] = useState<boolean>(false);

  const [initialValue, setInitialValue] =
    useState<INITIAL_FORM_FORMIK_VALUE>(INITIAL_FORM_STATE);

  useEffect(() => {
    dispatch(companyJobDetails(location.state.id));
  }, [dispatch, location.state.id]);

  const {
    JobDetailsData,
    itemCosting,
    quoteValidDate,
    title,
    summary,
    draft,
    notes,
    comments,
    _id,
    quoteNumber,
    downloadUrl,
  } = useSelector((state: ReducersModel) => state.jobDetailsModelReducer);

  useEffect(() => {
    if (quoteValidDate || summary || title || itemCosting) {
      setInitialValue({
        quoteSelection: location.state.quoteSelection,
        date: moment().format("YYYY-MM-DD"),
        validUntil: moment(quoteValidDate)
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        quoteNumber: quoteNumber,
        title: title,
        summary: summary,
        dragData: itemCosting,
        isDraft: draft,
        notes: notes,
        id: _id,
        comments: comments,
        location: {
          // Add this line
          address: "", // Use default or actual values as necessary
          coordinates: [], // Use default or actual values as necessary
        },
      });
    }
  }, [
    comments,
    draft,
    _id,
    itemCosting,
    notes,
    quoteNumber,
    quoteValidDate,
    summary,
    title,
    location.state.quoteSelection,
  ]);

  useEffect(() => {
    if (quoteValidDate || summary || title || itemCosting) {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: Utils.ActionName.SELECT_TEMPLATE_MODEL,
      payload: {
        data: initialValue,
        id: location.state.id,
      },
    });
  }, [location.state.id, dispatch, initialValue]);

  const handleSaveDraft = (values: INITIAL_FORM_FORMIK_VALUE) => {
    setDownloadIcon(true);
    // Assuming `location` can be a sensible default if not present in `values`
    const defaultLocation = { address: "", coordinates: [0, 0] };
    console.log(values.location, "Values of LOcation");
    const newAllValues = {
      ...values,
      isDraft: true,
      // Include `location` explicitly, using the value from `values` if present, or the default
      location: values.location || defaultLocation,
    };
    dispatch(
      SubmitSelectTemplate(
        newAllValues,
        location.state.id,
        location.state.quoteSelection,
        history
      )
    );
  };

  // Repeat a similar pattern for `handleSubmit` if necessary

  const handleClickPreview = () => {
    setDownloadIcon(true);
    history.push({
      pathname: Utils.Pathname.BUILD_QUOTE_TEMPLATE_PREVIEW,
      state: { id: location.state.id },
    });
  };

  const handleCancelButton = () => {
    setInitialValue(INITIAL_FORM_STATE);
    history.push("/home");
  };

  const handleSubmit = (values: INITIAL_FORM_FORMIK_VALUE) => {
    setDownloadIcon(true);

    // Assuming `location` can be a sensible default if not present in `values`
    const defaultLocation = { address: "", coordinates: [0, 0] };
    const newAllValues = {
      ...values,
      isDraft: true,
      // Include `location` explicitly, using the value from `values` if present, or the default
      location: values.location || defaultLocation,
    };
    dispatch(
      SubmitSelectTemplate(
        newAllValues,
        values.id, // Use values.id instead of location.state.id
        values.quoteSelection, // Use values.quoteSelection instead of location.state.quoteSelection
        history
      )
    );
  };

  console.log(JobDetailsData[0]?.isDraft, "isDraft");
  return (
    <Fragment>
      {!mobileView ? (
        <ThemeProvider theme={theme}>
          <Formik
            initialValues={{
              ...initialValue,
            }}
            validationSchema={Schema.SelectTemplateSchema}
            onSubmit={(values: INITIAL_FORM_FORMIK_VALUE) => {
              handleSubmit(values);
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, isValid, dirty }) => (
              <Form>
                {JobDetailsData.length > 0 &&
                  JobDetailsData.map((item: any) => {
                    return (
                      <Title>
                        <Typography variant="h4" color="text.secondary">
                          Build Quote
                        </Typography>
                        <Box className={classes.titleChildren}>
                          {item.isDraft || downloadIcon ? (
                            <Box
                              className={classes.titleIcons}
                              sx={{
                                width: "36px",
                                height: "36px",
                                display: "flex",
                                minWidth: "36px",
                                alignItems: "center",
                                padding: "0",
                              }}
                            >
                              <Button
                                onClick={handleClickPreview}
                                sx={{
                                  width: "36px",
                                  height: "36px",
                                  minWidth: "36px",
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "0",
                                }}
                              >
                                <VisibilityIcon
                                  sx={{
                                    color: "var(--primaryBlueColor)",
                                    marginTop: "-6px",
                                    position: "relative",
                                    top: "4px",
                                  }}
                                />
                              </Button>
                            </Box>
                          ) : (
                            <Box
                              className={classes.titleIcons}
                              sx={{
                                width: "36px",
                                height: "36px",
                                minWidth: "36px",
                                display: "flex",
                                alignItems: "center",
                                padding: "0",
                              }}
                            >
                              <Button
                                sx={{
                                  width: "36px",
                                  height: "36px",
                                  minWidth: "36px",
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "0",
                                }}
                              >
                                <VisibilityIcon
                                  sx={{
                                    color: "var(--modalHeadingColor)",
                                    marginTop: "-6px",
                                    position: "relative",
                                    top: "4px",
                                  }}
                                />
                              </Button>
                            </Box>
                          )}
                          <Box
                            className={classes.titleIcons}
                            sx={{
                              width: "36px",
                              height: "36px",
                              minWidth: "36px",
                              display: "flex",
                              alignItems: "center",
                              padding: "0",
                              margin: "0 20px",
                            }}
                          >
                            {item.isDraft || downloadIcon || downloadIcon ? (
                              <Link
                                href={downloadUrl}
                                underline="none"
                                color="secondary.contrastText"
                              >
                                <Button
                                  sx={{
                                    width: "36px",
                                    height: "36px",
                                    minWidth: "36px",
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "0",
                                  }}
                                >
                                  <FileDownloadIcon
                                    sx={{
                                      color: "var(--primaryBlueColor)",
                                      marginTop: "-6px",
                                      position: "relative",
                                      top: "4px",
                                    }}
                                  />
                                </Button>
                              </Link>
                            ) : (
                              <Button
                                sx={{
                                  width: "36px",
                                  height: "36px",
                                  minWidth: "36px",
                                  display: "flex",
                                  alignItems: "center",
                                  padding: "0",
                                }}
                              >
                                <FileDownloadIcon
                                  sx={{
                                    color: "var(--modalHeadingColor)",
                                    marginTop: "-6px",
                                    position: "relative",
                                    top: "4px",
                                  }}
                                />
                              </Button>
                            )}
                          </Box>
                          <Button
                            type="submit"
                            sx={{
                              size: "small",
                              borderRadius: "0px",
                              color: "var(--white)",
                              height: "36px",
                              display: "flex",
                              alignItems: "center",
                            }}
                            variant="contained"
                            onClick={() => handleSaveDraft(values)}
                          >
                            Save as Draft
                          </Button>
                        </Box>
                      </Title>
                    );
                  })}

                <PaperBody>
                  <Box className={classes.gridMuiroot}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={3.5}>
                        <Typography
                          sx={{ padding: "3px 0px 10px" }}
                          variant="h5"
                        >
                          Quote for
                        </Typography>

                        <TextfieldWrapper
                          name="quoteSelection"
                          className={classes.textField}
                          size="small"
                          value={
                            JobDetailsData.length > 0 &&
                            JobDetailsData.map(
                              (item: any, idx: number) =>
                                item?.job_detail?.clientDetail?.businessName
                            )
                          }
                          inputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={2.5}></Grid>

                      <Grid item xs={12} sm={6} md={2}>
                        <Typography
                          variant="h5"
                          sx={{ padding: "3px 0px 10px" }}
                        >
                          Date
                        </Typography>
                        <DateTimePicker
                          name="date"
                          size="small"
                          className={classes.textField}
                          disabled
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={2}>
                        <Typography
                          variant="h5"
                          sx={{ padding: "3px 0px 10px" }}
                        >
                          Valid Until
                        </Typography>
                        {values.date && (
                          <DateTimePicker
                            inputProps={{
                              min: moment(new Date(values.date)).format(
                                "YYYY-MM-DD"
                              ),
                              max: moment().add(1, "years").toDate(),

                              placeholder: "Please Select",
                            }}
                            name="validUntil"
                            size="small"
                            className={classes.textField}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={6} md={2}>
                        <Typography
                          variant="h5"
                          sx={{ padding: "3px 0px 10px" }}
                        >
                          Quote Number
                        </Typography>
                        <TextfieldWrapper
                          name="quoteNumber"
                          size="small"
                          inputProps={{ maxLength: 10 }}
                          className={classes.textField}
                        />
                      </Grid>

                      {/* <Grid item xs={12} sm={6} md={5}>
                        <TextfieldWrapper
                          name="summary"
                          size="small"
                          placeholder="Summary of what needs to be done"
                          className={classes.textField}
                        />
                      </Grid> */}

                      <Grid item xs={12} sm={6} md={5}>
                        <Typography sx={{ padding: "10px 0" }} variant="h5">
                          Location/Delivery Address
                        </Typography>
                        <Autocomplete
                          style={{
                            width: "100%",
                            height: "46px",
                            borderRadius: 0,
                            border: "1px solid #c9c2c2",
                            fontSize: "16px",
                            outline: "none",
                            // boxShadow: "-2px 0px 23px -2px rgba(0,0,0,0.33)",
                            paddingLeft: "12px",
                          }}
                          apiKey={"AIzaSyD_LK6zFQnwbZuRolqGuT4XpTEDjegZ2Yg"}
                          placeholder="Location"
                          options={{
                            types: ["geocode", "establishment"],
                          }}
                          onPlaceSelected={(place) => {
                            console.log(
                              "place",
                              place,
                              place?.geometry?.location
                            );
                            setFieldValue("location", {
                              address: place.formatted_address,
                              coordinates: [
                                place?.geometry?.location?.lng(),
                                place?.geometry?.location?.lat(),
                              ],
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={7}></Grid>
                      <Grid item xs={12} sm={6} md={5}>
                        <Typography sx={{ padding: "10px 0" }} variant="h5">
                          Quote Title
                        </Typography>
                        <TextfieldWrapper
                          name="title"
                          size="small"
                          placeholder="Title"
                          className={classes.textField}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <FieldArray
                    name="dragData"
                    render={(arrayHelpers: any) => {
                      return (
                        <DragTable
                          data={INITIAL_FORM_STATE.dragData}
                          arrayHelper={arrayHelpers}
                          value={values}
                          setFieldValue={setFieldValue}
                        />
                      );
                    }}
                  />
                  <Box className={classes.gridMuirootValue}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} sm={12} lg={12}>
                        <Typography sx={{ padding: "0px 0 10px" }} variant="h5">
                          Quote Payment Terms
                        </Typography>
                        <TextfieldWrapper
                          name="notes"
                          fullWidth
                          size="small"
                          className={classes.textField}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </PaperBody>
                <Box className={classes.buttonPositions}>
                  <Box className={classes.buttonAll}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{
                        borderRadius: "unset",
                        "&:hover": {
                          backgroundColor: "var(--buttonHover)",
                          color: "var(--white)",
                        },
                      }}
                      type="reset"
                      onClick={handleCancelButton}
                    >
                      Cancel
                    </Button>
                    <Box className={classes.buttonsaveDraft}>
                      <Button
                        type="submit"
                        variant="outlined"
                        color="secondary"
                        sx={{
                          borderRadius: "unset",
                          "&:hover": {
                            backgroundColor: "var(--buttonHover)",
                            color: "var(--white)",
                          },
                        }}
                        onClick={() => handleSaveDraft(values)}
                      >
                        Save as draft
                      </Button>
                      {JobDetailsData[0]?.isDraft ? (
                        <Button
                          type="submit"
                          sx={{
                            size: "small",
                            borderRadius: "0px",
                            color: "var(--white)",
                          }}
                          variant="contained"
                          onClick={() => handleSaveDraft(values)}
                        >
                          Send
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          sx={{
                            size: "small",
                            borderRadius: "0px",
                            color: "var(--white)",
                          }}
                          variant="contained"
                          onClick={() => handleSaveDraft(values)}
                        >
                          Submit
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </ThemeProvider>
      ) : (
        <MobileResponsiveQuote />
      )}
    </Fragment>
  );
};

export default SelectTemplate;
