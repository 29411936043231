import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  tableSelector: ({ theme }: any) => ({
    width: "40%",
    display: "flex",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: "5%",
    [theme.breakpoints.down(800)]: {
      marginTop: "15%",
      width: "87%",
    },
  }),

  headContainer: ({ theme }: any) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down(800)]: {
      display: "flex",
      flexDirection: "column",
    },

  }),

  Head: ({ theme }: any) => ({
    alignItems: "center",
    width: "288px",
    marginTop: "3%",
    padding: "15px",
    justifyContent: "space-between",
    display: "flex",
  }),

  searchAddContainer: ({ theme }: any) => ({
    paddingTop: "2ppx",
    paddingRight: "20px",
    display: "flex",
    // marginRight:"10%",
    justifyContent: "space-evenly",
    width: "45%",
    alignItems: "center",
    [theme.breakpoints.down(600)]: {
      width: "100%",
      marginBottom: "20px",
    },

  }),

  delete: () => ({
    cursor: "pointer",
  }),


  modalContainerStyle: ({ theme }: any) => ({
    minHeight: "252px",
    width: "455px",

  }),

  modalContainerStyle3: ({ theme }: any) => ({
    minHeight: "252px",
    width: "455px",
    [theme.breakpoints.down(600)]: {
      width: "100%",
      marginBottom: "20px",
    },

  }),

  modalContainerStyle2: ({ theme }: any) => ({
    minHeight: "252px",
    width: "455px",

  }),



  modalStyle: ({ theme }: any) => ({
    position: "absolute",
    textAlign: "center",
    width: 455,
    maxHeight: 1000,
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    backgroundColor: "white",
    [theme.breakpoints.down(600)]: {
      width: "95%",
      marginBottom: "20px",
    },

  }),
  updatemodalStyle: ({ theme }: any) => ({
    position: "absolute",
    textAlign: "center",
    width: 400,
    height: 260,
    top: "50%",
    left: "60%",
    transform: "translate(-50%,-50%)",
    backgroundColor: "white",
    [theme.breakpoints.down(600)]: {
      width: 327,
      height: 285,
      top: "50%",
      left: "49%",
    },
  }),


  modalStyleContainer: ({ theme }: any) => ({
    backgroundColor: "#fff",
    overflowY: "auto",
  }),

  modalHeader: () => ({
    padding: "30px",
    alignItems: "center",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  }),

  csvmodalHeader: () => ({
    padding: "18px 35px",
    alignItems: "center",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  }),

  modalHeader1: () => ({
    // padding: "30px",
    paddingLeft: "30px",
    alignItems: "start",
    width: "65%",
    marginBottom: "4%",
    textAlign: "start",
    // display: "flex",
    // justifyContent: "space-between",
  }),

  deleteButtonRes: () => ({

  }),

  deletemodalStyle: ({ theme }: any) => ({
    position: "absolute",
    textAlign: "center",
    width: 455,
    height: 220,
    top: "50%",
    left: "60%",
    transform: "translate(-50%,-50%)",
    backgroundColor: "white",
    [theme.breakpoints.down(600)]: {
      width: 327,
      height: 244,
      top: "50%",
      left: "50%",
    },
  }),
  assignModalMobileStyle: ({ theme }: any) => ({
    position: "absolute",
    textAlign: "center",
    width: 455,
    height: 220,
    top: "50%",
    left: "60%",
    transform: "translate(-50%,-50%)",
    backgroundColor: "white",
    [theme.breakpoints.down(600)]: {
      backgroundColor: "#E5E5E5",
      width: 444,
      height: "100%",
      top: "50%",
      left: "50%",
      overFlowX: "scroll"
    },
  }),




  multiInput: () => ({
    width: "95%",
    paddingLeft: "20px",
    paddingBottom: "10px",
  }),

  certificateTable: () => ({
    backgroundColor: "#FAFAFA",
    width: "95%",
    margin: "0 0 20px 20px",
    // paddingLeft:"20px",
    // paddingBottom:"10px",
  }),

  buttonContainer: ({ theme }: any) => ({
    width: "95%",
    [theme.breakpoints.down(600)]: {
      marginBottom: "50px !important",
    }

  }),

  //  tableMobileContainer:()=>({
  //    display:"flex"
  //  }),

  handlePermission: () => ({
    height: "17px",
    width: "160px",
    display: "flex",
    flexDirection: "row",
  }),

  modalActionButton: () => ({

    backgroundColor: "#0DA4CE",
  }),

  radioBorder: ({ theme }: any) => ({
    height: "190px",
    border: "1px solid #E0E0E0",
    marginTop: "2%",
    [theme.breakpoints.down(600)]: {
      height: "257px",
      marginTop: "3%",
    },
  }),

  dragDrop: ({ theme }: any) => ({
    width: "100%",
    cursor: "pointer",
  }),

  fileName: ({ theme }: any) => ({
    marginTop: "30%",
  }),

  csv: ({ theme }: any) => ({
    marginLeft: "12% !important",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down(600)]: {
      marginLeft: "0% !important",
    },
  }),

  csvLogo: ({ theme }: any) => ({
    width: "100px",
    height: "100px",
  }),

});

export { useStyles };
