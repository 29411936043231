import AWS from "aws-sdk";
import AwsCred from "./AWSCred";
import Utils from ".";

const { bucketRegion, BUCKETNAME,bucketUrl } = AwsCred;

const myBucket = new AWS.S3({
  params: { Bucket: BUCKETNAME },
  region: bucketRegion,
});

export const uploadFile = async (file: any) => {
  const formData = new FormData(); // Create FormData object
  formData.append('file', file); // Append the file to FormData object
 
  return new Promise((resolve, reject) => {
      Utils.API.postApiCall(
          Utils.endpoint.uploadProfile,
          formData,
          (respData: any) => {
              let { data } = respData;
              resolve({
                Location:`${bucketUrl}${data.data.image}`
              }); // Resolve the promise with the response data
          },
          (error: any) => {
              let { data } = error;
              Utils.showAlert(2, data.message);
              reject(data); // Reject the promise with the error data
          }
      );
  });
};

